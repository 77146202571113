var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.empresa && (!_vm.empresa.ativo || ![4, 5, 6].includes(_vm.empresa.tipo_empresa)))?_c('aviso-bloqueio'):_c('v-container',{attrs:{"flex":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"column":"","wrap":""}},[_c('v-toolbar',{staticClass:"\n        elevation-0\n        secondary--text\n        mb-4\n      ",staticStyle:{"background":"transparent"},attrs:{"color":"#eee"}},[_c('v-toolbar-title',[_vm._v(" Meios de Recebimento ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.dialogAutenticacao = true}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('h4',[_vm._v("Adicionar meio de recebimento")])]),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs,"width":"600","scrollable":"","persistent":""},model:{value:(_vm.cadRecegbimento),callback:function ($$v) {_vm.cadRecegbimento=$$v},expression:"cadRecegbimento"}},[_c('cad-pagamento',{on:{"carregar":function($event){_vm.listPagamentosExtra(),
            _vm.carregarPagamentosSecundarios()},"fechar":function($event){_vm.cadRecegbimento = false},"showSnackbar":_vm.showSnackbar}})],1)],1),[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","loading":_vm.loading,"items":_vm.pagamentosExtra,"headers":_vm.table_headers},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-xs-left"},[_c('v-avatar',[_c('v-icon',{staticClass:"primary--text"},[_vm._v("credit_card")])],1)],1),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(props.item.forma_pagamento.descricao)+" ")]),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(_vm.destinos[props.item.destino])+" ")]),_vm._l((_vm.tiposPagamento),function(tp){return _c('td',{key:tp.id_tipo_pagamento,staticClass:"text-xs-center"},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[(props.item.forma_pagamento[tp.referencia])?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-radio',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!props.item[tp.referencia]),expression:"!props.item[tp.referencia]"}],on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.ativarPagamento(props.item.id, tp.id_tipo_pagamento, 0); })($event)}}},on)),_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(props.item[tp.referencia]),expression:"props.item[tp.referencia]"}],attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.ativarPagamento(props.item.id, tp.id_tipo_pagamento, 1); })($event)}}},[_vm._v("check_circle")])],1)]}}],null,true)},[_vm._v(" Somente um pagamento com "+_vm._s(tp.descricao)+" pode ficar marcado como ativo. ")])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-avatar',_vm._g({},on),[_c('v-icon',{staticClass:"red--text"},[_vm._v("block")])],1)]}}],null,true)},[_vm._v(" Esta forma de pagamento não permite débito. ")])]],2)],1)}),_c('td',{staticStyle:{"width":"10px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"mt-4",attrs:{"readonly":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.habilitarPagamento(props.item.forma_pagamento.id); })($event)}},model:{value:(props.item.forma_pagamento.disponivel),callback:function ($$v) {_vm.$set(props.item.forma_pagamento, "disponivel", $$v)},expression:"props.item.forma_pagamento.disponivel"}},on))]}}],null,true)},[_vm._v(" Mudar dispobilidade ")])],1),_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.apagarPagamento(props.item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Apagar ")])],1)],2)]}}])})],1),_c('v-toolbar-title',{staticClass:"secondary--text mt-4 mb-2"},[_vm._v(" Meios recebimentos secundários ")]),_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","loading":_vm.loading,"items":_vm.pagamentosSecundarios,"headers":_vm.table_headers},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-xs-left"},[_c('v-avatar',[_c('v-icon',{staticClass:"teal--text"},[_vm._v("pix")])],1)],1),_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(props.item.forma_pagamento.descricao))]),_c('td',{staticClass:"text-xs-center"},[_vm._v(" "+_vm._s(_vm.destinos[props.item.destino])+" ")]),_vm._l((_vm.tiposPagamento),function(tp){return _c('td',{key:tp.id_tipo_pagamento,staticClass:"text-xs-center"},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[(props.item.forma_pagamento[tp.referencia])?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-radio',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!props.item[tp.referencia]),expression:"!props.item[tp.referencia]"}],on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.ativarPagamento(props.item.id, tp.id_tipo_pagamento, 0); })($event)}}},on)),_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(props.item[tp.referencia]),expression:"props.item[tp.referencia]"}],attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.ativarPagamento(props.item.id, tp.id_tipo_pagamento, 1); })($event)}}},[_vm._v("check_circle")])],1)]}}],null,true)},[_vm._v(" Somente um pagamento com "+_vm._s(tp.descricao)+" pode ficar marcado como ativo. ")])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-avatar',_vm._g({},on),[_c('v-icon',{staticClass:"red--text"},[_vm._v("block")])],1)]}}],null,true)},[_vm._v(" Esta forma de pagamento não ser vinculada. ")])]],2)],1)}),_c('td',{staticStyle:{"width":"10px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"mt-4",attrs:{"readonly":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.habilitarPagamento(props.item.forma_pagamento.id); })($event)}},model:{value:(props.item.forma_pagamento.disponivel),callback:function ($$v) {_vm.$set(props.item.forma_pagamento, "disponivel", $$v)},expression:"props.item.forma_pagamento.disponivel"}},on))]}}],null,true)},[_vm._v(" Mudar disponibilidade ")])],1),_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.apagarPagamento(props.item)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Apagar ")])],1)],2)]}}])})],1)],(_vm.dialogAutenticacao)?_c('v-dialog',{attrs:{"width":"35em"},model:{value:(_vm.dialogAutenticacao),callback:function ($$v) {_vm.dialogAutenticacao=$$v},expression:"dialogAutenticacao"}},[_c('autenticacao',{on:{"fechar":function($event){_vm.dialogAutenticacao = false},"callDialog":function($event){_vm.cadRecegbimento = true}}})],1):_vm._e()],2),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"multi-line":_vm.snackbar.multiLine,"timeout":_vm.snackbar.timeout,"vertical":_vm.snackbar.vertical},model:{value:(_vm.snackbar.active),callback:function ($$v) {_vm.$set(_vm.snackbar, "active", $$v)},expression:"snackbar.active"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar.active = false}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }