<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 3 && empresa.tipo_empresa !== 8)"/>
  <v-layout align-space-around justify-start fill-height column class="pt-5" v-else>
    <v-toolbar
      color="transparent"
      class="elevation-0 secondary--text"
      style="background: transparent">
      <v-toolbar-title>Integrações</v-toolbar-title>
    </v-toolbar>
    <v-card style="border-radius: 1.5em;" class="mb-4">
      <v-card-text>
        <v-layout justify-space-between>
          <v-flex xs11 style="background-color:#DCDCDC; border-radius: 1.5em;">
            <v-layout justify-space-around>
              <h3 class="mt-2" style="width:100%; text-align: center;">{{
                visivel ?
                (empresa && empresa.token ? descriptografarToken(this.empresa.token) : 'Não possui token') : (empresa && empresa.token ? ocultarToken(this.empresa.token) : 'Não possui token')
              }}</h3>
              <v-tooltip
                v-show="visivel"
                :color="color ? color : ''"
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="copiar"
                    v-show="visivel"
                  >
                    <v-icon size="18">content_copy</v-icon>
                  </v-btn>
                </template>
                <span>{{ texto }}</span>
              </v-tooltip>
            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="visivel=!visivel" class="ml-4">
                  <v-icon>{{ visivel ? 'visibility_off' : 'visibility' }}</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  visivel ? 'Esconder token de acesso' : 'Visializar token de acesso'
                }}
              </span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card>
      <v-tabs color="teal" v-model="tab">
        <v-tab v-if="empresa && empresa.tipo_empresa === 3">
          Resulmo da Integração
        </v-tab>
        <v-tab>Documentação</v-tab>
        <v-tab>Playground</v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="empresa && empresa.tipo_empresa === 3">
            <v-layout justify-space-between wrap>
              <v-flex xs12 sm12 md4>
                <v-card class="pa-3" width="100%" height="30em" elevation="0">
                  <v-card-title class="justify-center">
                    Postos Integrados
                  </v-card-title>
                  <v-card-text>
                    <v-layout justify-center>
                      <v-progress-circular
                        :rotate="360"
                        :size="300"
                        :width="15"
                        :value="percentualPostos"
                        color="teal"
                      >
                        <h1>{{ percentualPostos }}%</h1>
                      </v-progress-circular>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-card class="pa-3" width="100%" height="30em" elevation="0">
                  <v-card-title class="ml-4">
                    Compras do Dia
                  </v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(compra, index) in compras"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon color="teal">local_gas_station</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{compra.posto}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Número de compras: {{compra.numero_compras}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 class="mt-3">
                <v-card
                  class="pa-3 mx-auto text-center"
                  width="90%"
                  elevation="0"
                >
                  <v-card-text>
                    <v-sparkline
                      v-if="compras"
                      :labels="compras.map((v) => v.posto)"
                      :value="compras.map((v) => v.numero_compras)"
                      height="100"
                      padding="22"
                      smooth
                      auto-draw
                      :fill="true"
                      color="teal"
                      line-width="2"
                      stroke-linecap="round"
                    >
                    </v-sparkline>
                  </v-card-text>
                  <v-card-text>
                    <div class="text-xs-center mb-3">
                      <v-pagination
                        v-model="pagination.page"
                        :length="pages"
                        circle
                        color="teal"
                      ></v-pagination>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-select
              class="mt-4"
              label="Selecione uma API"
              v-model="documentacaoSelecionada"
              :items="lista_api"
              item-text="nome"
              return-object
              outlined
            ></v-select>
            <v-data-table
              hide-default-footer
              :headers="headersUrl"
              :items="itemsUrl"
              no-data-text="Não possui descrição"
              class="elevation-1 ma-4"
            >
              <template v-slot:item="props">
              <tr>
                <td class="text-xs-left">
                  {{props.item.ambiente}}
                </td>
                <td class="text-xs-left">
                  {{props.item.rota_base}}{{ documentacaoSelecionada ? documentacaoSelecionada.api : '' }}
                </td>
              </tr>
              </template>
            </v-data-table>
            <v-card class="ma-4 pa-0" color="gray" height="13em">
              <v-card-title style="font-size: 1em;" class="pa-2 ml-2">
                Headers
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-1">
                <pre>
                  {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer token_apetrus"
                  }
                </pre>
              </v-card-text>
            </v-card>
            <template
              v-if="documentacaoSelecionada"
            >
              <v-layout
                column
                v-for="(item, index) in documentacaoSelecionada.endpoints"
                :key="index"
                class="ma-4"
              >
                <h1
                  class="teal--text mb-4 mt-4"
                >
                  {{item.titulo}}
                </h1>
                <h3
                  style="font-weight: 400; font-size: 1.2em"
                  v-html="item.descricao"
                ></h3>
                <v-card
                  class="ma-4 pa-0"
                  color="gray"
                >
                  <v-card-text class="ma-0 pa-1">
                    <v-layout justify-space-around wrap>
                      <v-flex
                        xs10
                        class="ma-0 pa-0"
                      >
                        <h4 style="text-align: center;" class="mb-4">
                          URL: {{ item.url }}
                        </h4>
                        <v-divider></v-divider>
                        <h4 class="mb-2 mt-2">METHOD: {{item.method}}</h4>
                        <v-divider></v-divider>
                        <h4 v-if="item.body.length > 0" class="mb-2 mt-2">
                          BODY:
                        </h4>
                        <visualizar-codigo
                          v-if="item.body.length > 0"
                          :endpoint="item"
                        />
                      </v-flex>
                      <v-flex xs1 class="ma-0 pa-0">
                        <v-tooltip
                          :color="color ? color : ''"
                          top
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              @click="copiarURL(`${rota_producao}${documentacaoSelecionada.api}${item.id || ''}${item.rota || ''}`)"
                            >
                              <v-icon size="20">content_copy</v-icon>
                            </v-btn>
                          </template>
                          <span>Copiar URL</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
                <h3 class="teal--text" style="width: 100%; text-align: center;">
                  Parâmetros
                </h3>
                <v-data-table
                  hide-default-footer
                  disable-pagination
                  :headers="headers"
                  :items="item.table.items"
                  no-data-text="Não possui descrição"
                  class="elevation-1 ma-4 pa-0"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-xs-left">
                        <v-checkbox
                          color="primary"
                          class="mt-3"
                          v-model="props.item.inserido"
                          @click="addCampo(item, props.item)"
                        />
                      </td>
                      <td class="text-xs-left">
                        {{props.item.atributo}}
                      </td>
                      <td class="text-xs-left">
                        {{props.item.descricao}}
                      </td>
                      <td>
                        {{props.item.obrigatorio}}
                      </td>
                      <td>
                        {{props.item.tipo}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-card class="ma-4 pa-0" color="gray">
                  <v-card-title
                    style="font-size: 1em;"
                    class="pa-2 ml-2"
                  >
                    Retorno
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-tabs color="teal" v-model="tab2">
                      <v-tab>
                        Sucesso
                      </v-tab>
                      <v-tab>
                        Erro
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab2" style="background-color: #1E1E1E">
                      <v-tab-item>
                        <v-card style="background-color: #1E1E1E">
                          <v-card-text>
                            <visualizar-codigo
                              :codigo="JSON.stringify(item.mensagem_sucesso, null, 2)"
                            />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item style="background-color: #1E1E1E">
                        <v-card style="background-color: #1E1E1E">
                          <v-card-text>
                            <visualizar-codigo
                              :codigo="JSON.stringify(item.mensagem_erro, null, 2)"
                            />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                </v-card>
                <h3 class="teal--text" style="width: 100%; text-align: center;">
                  Atributos Retornados
                </h3>
                <v-data-table
                  hide-default-footer
                  disable-pagination
                  :headers="headersReturn"
                  :items="item.itemsReturn"
                  no-data-text="Não possui descrição"
                  class="elevation-1 ma-4 pa-0"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-xs-left">
                        {{props.item.atributo}}
                      </td>
                      <td class="text-xs-left">
                        {{props.item.descricao}}
                      </td>
                      <td>
                        {{props.item.tipo}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>

              </v-layout>
              <v-layout justify-end class="mt-4" v-if="documentacaoSelecionada.combustiveis">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Combustíveis Disponíveis na Apetrus
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(combustivel, index) in combustiveis"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-tooltip :color="combustivel.color" top>
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <svg-filler
                                  :path="combustivel.icone ? require(`@/assets/combs/${combustivel.icone}.svg`) : require(`@/assets/combs/gas.svg`)"
                                  width="40px"
                                  height="40px"
                                  :fill="combustivel.color"
                                />
                              </span>
                            </template>
                            <span>{{combustivel.nome}}</span>
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ combustivel.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ `Referencia: ${combustivel.ref}` }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4"  v-if="documentacaoSelecionada.bandeiras">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Bandeiras de Cartões
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(bandeira, index) in bandeiras"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            ID: {{ bandeira.id }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Referencia: {{ bandeira.referencia }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4"  v-if="documentacaoSelecionada.tipos_pagamento">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Tipos de Pagamento
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(tipo, index) in tipos_pagamento"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            Descrição: {{ tipo.descricao }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Referência: {{ tipo.referencia }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="documentacaoSelecionada.tipos_veiculo">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Tipos de Veículo
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(tipo, index) in tipos_veiculo"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            Nome: {{ tipo.denominacao }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="documentacaoSelecionada.opcionais">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Opicionais para Veículo
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(opcional, index) in opcionais"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>bluetooth_drive</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            Nome: {{ opcional.nome }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            codigo: {{ opcional.codigo }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="documentacaoSelecionada.tipos_direcao">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Tipos de Direção
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(tipo_direcao, index) in tipos_direcao"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            Nome: {{ tipo_direcao.text }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            ID: {{ tipo_direcao.value }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="documentacaoSelecionada.tipos_cambio">
                <v-flex xs12>
                  <v-card>
                    <v-card-title class="justify-center teal--text">
                      Tipos de Câmbio
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list
                      v-for="(tipo_cambio, index) in tipos_cambio"
                      :key="index"
                    >
                      <v-list-item>
                        <v-list-item-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            Nome: {{ tipo_cambio.text }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            ID: {{ tipo_cambio.value }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
            </template>
          </v-tab-item>
          <v-tab-item>
            <template v-for="(item, index) in lista_api">
              <v-layout :key="`${index}-layout`" column>
                <h1
                  style="
                    width: 100%;
                    margin: 1em 0em;
                  "
                >
                  {{ item.nome }}
                </h1>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(endpoint, i) in item.endpoints"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <v-layout wrap>
                        <v-flex xs3 sm2 md1>
                          <v-avatar
                            :color="getColor(endpoint.method)"
                            size="45"
                            tile
                            rounded
                            width="4em"
                            height="1.5em"
                          >
                          <b class="white--text">{{ endpoint.method }}</b>
                        </v-avatar>
                        </v-flex>
                        <v-flex xs9 sm10 md11>
                          <h3 style="font-weight: normal;">
                            {{item.api}}{{ endpoint.id ? 'ID/' : '' }}{{ endpoint.rota }}
                          </h3>
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <editar-codigo
                        :endpoint="endpoint"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>

import AvisoBloqueio from '@/components/AvisoBloqueio'
import EditarCodigo from '@/components/EditarCodigo'
import VisualizarCodigo from '@/components/VisualizarCodigo.vue'
export default {
  components: { AvisoBloqueio, EditarCodigo, VisualizarCodigo },
  data: () => ({
    visivel: false,
    texto: 'Copiar token',
    headersUrl: [
      {
        text: 'Ambiente',
        sortable: false
      },
      {
        text: 'Rota base',
        sortable: false
      }
    ],
    documentacaoSelecionada: undefined,
    lista_api: [
      {
        nome: 'Postos de Combustível',
        api: '/automacao/',
        endpoints: [
          {
            titulo: 'Pegar Compras',
            descricao: 'Esse endpoint é responsável por pegar uma lista de compras de um posto integrado. Elas podem ser pegas por dia, mês e ano de foram realizadas.',
            rota: 'compras/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/compras/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                codigo: 'ASGW',
                abastecido: false,
                data_compra: '2024-04-15',
                disponivel: true,
                metodo_pagamento: 'prepago',
                confirmado: true
              }
            ],
            table: {
              items: [
                {
                  atributo: 'data_compra',
                  descricao: 'Esse atributo é responsável por informar a data de realização da compra. podendo ser informada a data exata (data_compra=15/04/2024) ou o mês (data_compra__month=10) e ano (data_compra__year=2022) de realização da compra',
                  obrigatorio: 'SIM',
                  tipo: 'Data',
                  inserido: false,
                  valor: '15/04/2024'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as compras. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'status',
                  descricao: 'Esse atributo é responsável por informar se compra foi ou abastecida (true) ou não (false).',
                  obrigatorio: 'Não',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: 'true'
                },
                {
                  atributo: 'confirmado',
                  descricao: 'Esse atributo é responsável por informar se compra foi ou paga (true) ou não (false).',
                  obrigatorio: 'Não',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: 'true'
                },
                {
                  atributo: 'metodo_pagamento',
                  descricao: 'Esse atributo é responsável por informar se a compra foi paga no app (prepago) ou se foi paga no posto (pospago).',
                  obrigatorio: 'Não',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'pospago'
                },
                {
                  atributo: 'codigo',
                  descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                  obrigatorio: 'Não',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                },
                {
                  atributo: 'disponivel',
                  descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false)',
                  obrigatorio: 'Não',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'codigo',
                descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'abastecido',
                descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                tipo: 'Booleano'
              },
              {
                atributo: 'metodo_pagamento',
                descricao: 'Informa o método de pagamento escolhindo, podendo ser pospago (Pago no Estabelecimento) ou prepago (pago no App Apetrus).',
                tipo: 'Texto'
              },
              {
                atributo: 'data_compra',
                descricao: 'Esse atributo é responsável por informar a data de realização da compra.',
                tipo: 'Data'
              },
              {
                atributo: 'disponivel',
                descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'confirmado',
                descricao: 'Esse atributo é responsável por informar se a compra foi paga (true) ou não (false).',
                tipo: 'Booleano'
              }
            ]
          },
          {
            titulo: 'Pegar Informações da compra',
            descricao: 'Esse endpoint é responsável por pegar as informações de uma compra, através do código da compra ou placa do veículo vinculado a compra.',
            rota: 'pegarinformacoes/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegarinformacoes/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              codigo: 'EAJT',
              nsu: '663078',
              codigo_autorizacao: '599137',
              valor: '1.00',
              valor_total: '1.00',
              data_compra: '2024-04-15',
              horario_compra: '11:48:00',
              confirmado: true,
              cancelado: false,
              disponivel: true,
              metodo_pagamento: 'prepago',
              combustivel: 'Gasolina premium',
              desconto: 12,
              tipo_oferta: 'Convênio',
              tipo_pagamento: 'credito',
              cashposto: false,
              itens_vendidos: [
                {
                  item: {
                    nome: 'Gasolina premium',
                    preco: 7.80,
                    tipo: 'P',
                    quantidade_disponivel: null,
                    disponivel: true,
                    categoria: 'Combustível'
                  },
                  quantidade: null,
                  preco_unitario: 7.80,
                  total: 7.80,
                  desconto: 0.95
                }
              ],
              cartao: {
                bandeira: 'VISA',
                id_bandeira: 1,
                final: '1234',
                id_cartao: 1285
              },
              empresa: {
                razao_social: 'Empresa compradora',
                cnpj: '13021917000114'
              }
            },
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'codigo',
                  descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                },
                {
                  atributo: 'placa',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo vinculado a compra.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                },
                {
                  atributo: 'confirmado',
                  descricao: 'Esse atributo é responsável por informar se compra foi ou paga (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'disponivel',
                  descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'codigo',
                descricao: 'Informa o código identificador da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'nsu',
                descricao: 'Informa o NSU da compra, caso a compra tenha sido realizado com o cartão.',
                tipo: 'Texto'
              },
              {
                atributo: 'codigo_autorizacao',
                descricao: 'Informa o Código de Autorização da compra, caso a compra tenha sido realizado com o cartão.',
                tipo: 'Texto'
              },
              {
                atributo: 'valor',
                descricao: 'Informa o valor da compra com o desconto aplicado, caso haja.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'valor_total',
                descricao: 'Informa o valor total da compra sem considerar o desconto.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'data_compra',
                descricao: 'Informa a data de realização da compra.',
                tipo: 'Data'
              },
              {
                atributo: 'horario_compra',
                descricao: 'Informa o horário de realização da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'confirmado',
                descricao: 'Esse atributo é responsável por informar se a compra foi paga (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'disponivel',
                descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'cancelado',
                descricao: 'Informa se a compra foi cancelada ou não.',
                tipo: 'Booleano'
              },
              {
                atributo: 'metodo_pagamento',
                descricao: 'Informa o método de pagamento escolhindo, podendo ser pospago (Pago no Estabelecimento) ou prepago (pago no App Apetrus).',
                tipo: 'Texto'
              },
              {
                atributo: 'cashposto',
                descricao: 'Informa se o desconto da compra foi dado em cashposto (cashback do posto)',
                tipo: 'Booleano'
              },
              {
                atributo: 'tipo_pagamento',
                descricao: 'Informa o tipo de pagamento escolhindo.',
                tipo: 'Texto'
              },
              {
                atributo: 'cashposto',
                descricao: 'Informa se o desconto da compra foi dado através de cashposto (cashback do posto).',
                tipo: 'Booleano'
              },
              {
                atributo: 'combustivel',
                descricao: 'Informa o combustível escolhido.',
                tipo: 'Texto'
              },
              {
                atributo: 'itens_vendidos',
                descricao: 'Dados do item que foi vendido',
                tipo: 'Conjunto'
              },
              {
                atributo: 'itens_vendidos.quantidade',
                descricao: 'Quantidade de foi vendida',
                tipo: 'Inteiro'
              },
              {
                atributo: 'itens_vendidos.preco_unitario',
                descricao: 'Preço do produto no momento da compra',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'itens_vendidos.desconto',
                descricao: 'Desconto obtido',
                tipo: 'Inteiro'
              },
              {
                atributo: 'itens_vendidos.item',
                descricao: 'Dados do item',
                tipo: 'Conjunto'
              },
              {
                atributo: 'itens_vendidos.item.nome',
                descricao: 'Nome do item',
                tipo: 'Texto'
              },
              {
                atributo: 'itens_vendidos.item.preco',
                descricao: 'Preço atual do produto',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'itens_vendidos.item.tipo',
                descricao: 'Tipo do Item (P: Produto Físico, S: Serviço, D: Produto Digital)',
                tipo: 'Texto'
              },
              {
                atributo: 'itens_vendidos.item.quantidade_disponivel',
                descricao: 'Quantidade disponivel em estoque. Caso seja null o controle de estoque está desativado.',
                tipo: 'Inteiro'
              },
              {
                atributo: 'itens_vendidos.item.disponivel',
                descricao: 'Informa se o item está disponivel em estoque.',
                tipo: 'Booleano'
              },
              {
                atributo: 'itens_vendidos.item.categoria',
                descricao: 'Nome da categoria do item',
                tipo: 'Booleano'
              },
              {
                atributo: 'cartao',
                descricao: 'Dados do cartão',
                tipo: 'Conjunto'
              },
              {
                atributo: 'cartao.bandeira',
                descricao: 'Bandeira do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'cartao.id_bandeira',
                descricao: 'ID da Bandeira do cartão',
                tipo: 'Inteiro'
              },
              {
                atributo: 'cartao.final',
                descricao: 'Ultimos digitos do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'cartao.id_cartao',
                descricao: 'ID do cartão',
                tipo: 'Inteiro/Nulo'
              },
              {
                atributo: 'empresa',
                descricao: 'Dados da empresa compradora',
                tipo: 'Conjunto'
              },
              {
                atributo: 'empresa.razao_social',
                descricao: 'Razão Social da empresa compradora',
                tipo: 'Texto'
              },
              {
                atributo: 'empresa.cnpj',
                descricao: 'CNPJ da empresa compradora',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Informações do Voucher',
            descricao: 'Esse endpoint é responsável por pegar as informações de um voucher apto para leitura, através do código da compra ou placa do veículo vinculado ao voucher.',
            rota: 'pegar-informacoes-voucher/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-informacoes-voucher/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              compra: {
                codigo: 'JFER',
                nsu: '34',
                valor: '2.00',
                valor_total: '2.00',
                data_compra: '2024-04-15',
                horario_compra: '12:01:00',
                confirmado: true,
                disponivel: true,
                cancelado: false,
                metodo_pagamento: 'prepago',
                tipo_pagamento: 'credito',
                cashposto: false,
                combustivel: 'Gasolina premium',
                desconto: 12,
                tipo_oferta: 'Convênio',
                itens_vendidos: [
                  {
                    item: {
                      nome: 'Gasolina premium',
                      preco: 7.80,
                      tipo: 'P',
                      quantidade_disponivel: null,
                      disponivel: true,
                      categoria: 'Combustível'
                    },
                    quantidade: 1,
                    preco_unitario: 7.80,
                    total: 2.00,
                    desconto: 12
                  }
                ],
                cartao: {
                  bandeira: 'Visa',
                  final: '1234'
                },
                empresa: {
                  razao_social: 'Empresa compradora',
                  cnpj: '13021917000114'
                }
              },
              numero: 'CCXBN4FQYF',
              disponivel: true,
              data_inativado: null,
              hora_inativado: '00:00:00',
              valor: '2.00',
              cpf_na_nota: true,
              posto: '98834148000126'
            },
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'codigo',
                  descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                },
                {
                  atributo: 'placa',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo vinculado a compra.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'numero',
                descricao: 'Código de identificação do voucher.',
                tipo: 'Texto'
              },
              {
                atributo: 'disponivel',
                descricao: 'Informa se o voucher está disponível para leitura ou não.',
                tipo: 'Booleano'
              },
              {
                atributo: 'data_inativado',
                descricao: 'Data de realização da leitura do voucher.',
                tipo: 'Data'
              },
              {
                atributo: 'hora_inativado',
                descricao: 'Hora de realização da leitura do voucher.',
                tipo: 'Texto'
              },
              {
                atributo: 'valor',
                descricao: 'Valor do voucher.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra',
                descricao: 'Informa um conjunto de informações da compra realizada.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.codigo',
                descricao: 'Informa o código identificador da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.nsu',
                descricao: 'Informa o NSU da compra, caso a compra tenha sido realizado com o cartão.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.valor',
                descricao: 'Informa o valor da compra com o desconto aplicado, caso haja.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.valor_total',
                descricao: 'Informa o valor total da compra sem considerar o desconto.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.data_compra',
                descricao: 'Informa a data de realização da compra.',
                tipo: 'Data'
              },
              {
                atributo: 'compra.horario_compra',
                descricao: 'Informa o horário de realização da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.confirmado',
                descricao: 'Esse atributo é responsável por informar se ac compra foi paga (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.disponivel',
                descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.cancelado',
                descricao: 'Informa se a compra foi cancelada ou não.',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.metodo_pagamento',
                descricao: 'Informa o método de pagamento escolhindo, podendo ser pospago (Pago no Estabelecimento) ou prepago (pago no App Apetrus).',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.tipo_pagamento',
                descricao: 'Informa o tipo de pagamento escolhindo.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.cashposto',
                descricao: 'Informa se o desconto da compra foi dado em cashposto (cashback do posto)',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.combustivel',
                descricao: 'Informa o combustível escolhido.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.desconto',
                descricao: 'Informa o percentual de desconto obtido.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.tipo_oferta',
                descricao: 'Informa o tipo de oferta utilizada na realização da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos',
                descricao: 'Dados do item que foi vendido',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.itens_vendidos.quantidade',
                descricao: 'Quantidade de foi vendida',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.preco_unitario',
                descricao: 'Preço do produto no momento da compra',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.itens_vendidos.desconto',
                descricao: 'Desconto obtido',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.item',
                descricao: 'Dados do item',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.itens_vendidos.item.nome',
                descricao: 'Nome do item',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos.item.preco',
                descricao: 'Preço atual do produto',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.itens_vendidos.item.tipo',
                descricao: 'Tipo do Item (P: Produto Físico, S: Serviço, D: Produto Digital)',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos.item.quantidade_disponivel',
                descricao: 'Quantidade disponivel em estoque. Caso seja null o controle de estoque está desativado.',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.item.disponivel',
                descricao: 'Informa se o item está disponivel em estoque.',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.itens_vendidos.item.categoria',
                descricao: 'Nome da categoria do item',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.cartao',
                descricao: 'Dados do cartão',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.cartao.bandeira',
                descricao: 'Bandeira do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.cartao.final',
                descricao: 'Ultimos digitos do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.empresa',
                descricao: 'Dados da empresa compradora',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.empresa.razao_social',
                descricao: 'Razão Social da empresa compradora',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.empresa.cnpj',
                descricao: 'CNPJ da empresa compradora',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Frentistas',
            descricao: 'Esse endpoint é responsável por pegar uma lista de frentistas de um posto integrado.',
            rota: 'frentistas/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/frentistas/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                nome: 'João',
                sobrenome: 'Silva',
                matricula: '1234',
                gerente: true,
                ativo: true,
                cpf: '12345678910',
                posto: '98834148000126'
              }
            ],
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'nome',
                descricao: 'Primeiro nome do frentista',
                tipo: 'Texto'
              },
              {
                atributo: 'sobrenome',
                descricao: 'Sobrenome do frentista',
                tipo: 'Texto'
              },
              {
                atributo: 'matricula',
                descricao: 'Matrícula do frentista',
                tipo: 'Texto'
              },
              {
                atributo: 'gerente',
                descricao: 'Informa se o frentista é gerente de pista.',
                tipo: 'Booleano'
              },
              {
                atributo: 'ativo',
                descricao: 'Informa se o frentista está ativo no sistema.',
                tipo: 'Booleano'
              },
              {
                atributo: 'cpf',
                descricao: 'CPF do frentista',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Vouchers',
            descricao: 'Esse endpoint é responsável por pegar uma lista de vouchers de um posto integrado. Os vouchers podem ser pegos por dia, mês e ano de sua realização.',
            rota: 'vouchers/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/vouchers/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                compra: {
                  codigo: 'JFER',
                  nsu: '34',
                  valor: '2.00',
                  valor_total: '2.00',
                  data_compra: '2024-04-15',
                  horario_compra: '12:01:00',
                  confirmado: true,
                  disponivel: true,
                  cancelado: false,
                  metodo_pagamento: 'prepago',
                  tipo_pagamento: 'credito',
                  cashposto: false,
                  combustivel: 'Gasolina premium',
                  desconto: 12,
                  tipo_oferta: 'Convênio',
                  itens_vendidos: [
                    {
                      item: {
                        nome: 'Gasolina premium',
                        preco: 7.80,
                        tipo: 'P',
                        quantidade_disponivel: null,
                        disponivel: true,
                        categoria: 'Combustível'
                      },
                      quantidade: 1,
                      preco_unitario: 7.80,
                      total: 2.00,
                      desconto: 12
                    }
                  ],
                  cartao: {
                    bandeira: 'Visa',
                    final: '1234'
                  },
                  empresa: {
                    razao_social: 'Empresa compradora',
                    cnpj: '13021917000114'
                  }
                },
                numero: 'CCXBN4FQYF',
                disponivel: true,
                data_inativado: null,
                hora_inativado: '00:00:00',
                valor: '2.00',
                cpf_na_nota: true,
                posto: '98834148000126'
              }
            ],
            table: {
              items: [
                {
                  atributo: 'data_gerado',
                  descricao: 'Esse atributo é responsável por informar a data que for gerado o voucher. podendo ser informada a data exata (data_gerado=15/04/2024) ou o mês (data_gerado__month=10) e ano (data_gerado__year=2022).',
                  obrigatorio: 'SIM',
                  tipo: 'Data',
                  inserido: false,
                  valor: '15/04/2024'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'nsu',
                  descricao: 'Esse atributo é responsável por informar o NSU da compra que se deseja obter as informações.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1234'
                },
                {
                  atributo: 'codigo',
                  descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'DTFG'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'numero',
                descricao: 'Código de identificação do voucher.',
                tipo: 'Texto'
              },
              {
                atributo: 'disponivel',
                descricao: 'Informa se o voucher está disponível para leitura ou não.',
                tipo: 'Booleano'
              },
              {
                atributo: 'data_inativado',
                descricao: 'Data de realização da leitura do voucher.',
                tipo: 'Data'
              },
              {
                atributo: 'hora_inativado',
                descricao: 'Hora de realização da leitura do voucher.',
                tipo: 'Texto'
              },
              {
                atributo: 'valor',
                descricao: 'Valor do voucher.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra',
                descricao: 'Informa um conjunto de informações da compra realizada.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.codigo',
                descricao: 'Informa o código identificador da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.nsu',
                descricao: 'Informa o NSU da compra, caso a compra tenha sido realizado com o cartão.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.valor',
                descricao: 'Informa o valor da compra com o desconto aplicado, caso haja.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.valor_total',
                descricao: 'Informa o valor total da compra sem considerar o desconto.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.data_compra',
                descricao: 'Informa a data de realização da compra.',
                tipo: 'Data'
              },
              {
                atributo: 'compra.horario_compra',
                descricao: 'Informa o horário de realização da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.confirmado',
                descricao: 'Esse atributo é responsável por informar se a compra foi paga (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.disponivel',
                descricao: 'Informa se a compra está disponivel para leitura (true) ou seja já foi lida (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.cancelado',
                descricao: 'Informa se a compra foi cancelada ou não.',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.metodo_pagamento',
                descricao: 'Informa o método de pagamento escolhindo, podendo ser pospago (Pago no Estabelecimento) ou prepago (pago no App Apetrus).',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.cashposto',
                descricao: 'Informa se o desconto da compra foi dado através de cashposto (cashback do posto).',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.tipo_pagamento',
                descricao: 'Informa o tipo de pagamento escolhindo.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.cashposto',
                descricao: 'Informa se o desconto da compra foi dado em cashposto (cashback do posto)',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.combustivel',
                descricao: 'Informa o combustível escolhido.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.desconto',
                descricao: 'Informa o percentual de desconto obtido.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.tipo_oferta',
                descricao: 'Informa o tipo de oferta utilizada na realização da compra.',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos',
                descricao: 'Dados do item que foi vendido',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.itens_vendidos.quantidade',
                descricao: 'Quantidade de foi vendida',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.preco_unitario',
                descricao: 'Preço do produto no momento da compra',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.itens_vendidos.desconto',
                descricao: 'Desconto obtido',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.item',
                descricao: 'Dados do item',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.itens_vendidos.item.nome',
                descricao: 'Nome do item',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos.item.preco',
                descricao: 'Preço atual do produto',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'compra.itens_vendidos.item.tipo',
                descricao: 'Tipo do Item (P: Produto Físico, S: Serviço, D: Produto Digital)',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.itens_vendidos.item.quantidade_disponivel',
                descricao: 'Quantidade disponivel em estoque. Caso seja null o controle de estoque está desativado.',
                tipo: 'Inteiro'
              },
              {
                atributo: 'compra.itens_vendidos.item.disponivel',
                descricao: 'Informa se o item está disponivel em estoque.',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.itens_vendidos.item.categoria',
                descricao: 'Nome da categoria do item',
                tipo: 'Booleano'
              },
              {
                atributo: 'compra.cartao',
                descricao: 'Dados do cartão',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.cartao.bandeira',
                descricao: 'Bandeira do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.cartao.final',
                descricao: 'Ultimos digitos do cartão',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.empresa',
                descricao: 'Dados da empresa compradora',
                tipo: 'Conjunto'
              },
              {
                atributo: 'compra.empresa.razao_social',
                descricao: 'Razão Social da empresa compradora',
                tipo: 'Texto'
              },
              {
                atributo: 'compra.empresa.cnpj',
                descricao: 'CNPJ da empresa compradora',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Realizar Leitura de Voucher',
            descricao: 'Esse endpoint é responsável por realizar a leitura de uma compra pospaga e prepaga, através do código da compra.',
            rota: 'realizar-leitura/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/realizar-leitura/',
            nome_lista: undefined,
            body: [
              'posto: 98834148000126',
              'matricula: 1234',
              'codigo: DTFG'
            ],
            method: 'PUT',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              sucesso: 'Leitura realizada com sucesso'
            },
            table: {
              items: [
                {
                  atributo: 'matricula',
                  descricao: 'Esse atributo é responsável por informar a matrícula do frentista que deseja realizar o abastecimento.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '1234'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '98834148000126'
                },
                {
                  atributo: 'codigo',
                  descricao: 'Esse atributo é responsável por informar o codigo identificador da compra.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'DTFG'
                },
                {
                  atributo: 'hodometro',
                  descricao: 'Esse atributo é responsável por informar o odômetro do veículo do funcionario que realizou a compra.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1000'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'sucesso',
                descricao: 'Mensagem de sucesso.',
                tipo: 'Texto'
              },
              {
                atributo: 'erro',
                descricao: 'Mensagem de error.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Crédito por Veículo',
            descricao: 'Esse endpoint é responsável por pegar o credíto disponível para um veículo. Caso o veículo possua mais de um crédito, a API retornará o crédito com maior valor disponível.',
            rota: 'pegar-credito/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-credito/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              id: 137,
              veiculo: {
                tipo_veiculo: 'carro',
                identificacao: 'XXX5X60',
                cor: 'branca',
                modelo: 'LOGAN',
                marca: 'Renault'
              },
              proposta: {
                empresa: '31830036000126',
                identificacao: '26183',
                tem_restricao_combustivel: true,
                combustivel: 'gasolina'
              },
              valor_disponivel: 196.54
            },
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável informar a placa do veículo. Essa placa deve ser passada sem os caracteres especiais.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'XXX5X60'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'id',
                descricao: 'Identificador único da entidade crédito.',
                tipo: 'Número'
              },
              {
                atributo: 'valor_disponivel',
                descricao: 'Valor disponível para uso.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'veiculo',
                descricao: 'Conjuto de informações pertencentes a um Veículo.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'veiculo.tipo_veiculo',
                descricao: 'Tipo de veículo (Carro, Motocicleta, Caminhão, SUV)',
                tipo: 'Texto'
              },
              {
                atributo: 'veiculo.identificacao',
                descricao: 'Placa do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'veiculo.cor',
                descricao: 'Cor do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'veiculo.modelo',
                descricao: 'Modelo do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'veiculo.marca',
                descricao: 'Marca do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'proposta',
                descricao: 'Conjuto de informações pertencentes a uma Proposta.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'proposta.identificacao',
                descricao: 'Identificador da proposta',
                tipo: 'Número'
              },
              {
                atributo: 'proposta.empresa',
                descricao: 'Empresa responsável proposta',
                tipo: 'Número'
              },
              {
                atributo: 'proposta.tem_restricao_combustivel',
                descricao: 'Informa se a proposta tem uma restrição de uso para um único combustível.',
                tipo: 'Booleano'
              },
              {
                atributo: 'proposta.combustivel',
                descricao: 'Informa a referencia do combustível que a proposta está destinada.',
                tipo: 'Booleano'
              }
            ]
          },
          {
            titulo: 'Gerar Voucher por Crédito',
            descricao: 'Esse endpoint é responsável por gerar um voucher já consumido a partir do crédito disponibilizado para um veículo',
            rota: 'gerar-voucher-com-creditos/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/gerar-voucher-com-creditos/',
            nome_lista: undefined,
            body: [
              'posto: 98834148000126',
              'id_funcionario: 90',
              'valor: 50.00',
              'id_credito: 137',
              'combustivel: gasolina',
              'matricula: 1234'
            ],
            method: 'POST',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              sucesso: 'Voucher gerado com sucesso'
            },
            table: {
              items: [
                {
                  atributo: 'matricula',
                  descricao: 'Esse atributo é responsável por informar a matrícula do frentista que deseja realizar o abastecimento.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '1234'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '98834148000126'
                },
                {
                  atributo: 'id_credito',
                  descricao: 'Esse atributo é responsável por informar o identificador do crédito usado pelo funcionário.',
                  obrigatorio: 'SIM',
                  tipo: 'Número',
                  inserido: true,
                  valor: 137
                },
                {
                  atributo: 'id_funcionario',
                  descricao: 'Esse atributo é responsável por informar o identificador do funcionário que deseja usar o crédito do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Número',
                  inserido: true,
                  valor: 90
                },
                {
                  atributo: 'combustivel',
                  descricao: 'Esse atributo é responsável por informar a referencia do combustível que deseja abastecer.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'gasolina'
                },
                {
                  atributo: 'hodometro',
                  descricao: 'Esse atributo é responsável por informar o odômetro do veículo do funcionario que realizou a compra.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1000'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'sucesso',
                descricao: 'Mensagem de sucesso.',
                tipo: 'Texto'
              },
              {
                atributo: 'erro',
                descricao: 'Mensagem de error.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Funcionários',
            descricao: 'Esse endpoint é responsável por pegar uma lista de funcionários de uma empresa que possua proposta (Uma acordo) com o posto informado.',
            rota: 'funcionarios/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/funcionarios/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                nome: 'João',
                id_funcionario: 90
              }
            ],
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações da compra. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os funcionários. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '63939714000181'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'id_funcionario',
                descricao: 'Identificador do funcionário',
                tipo: 'Número'
              },
              {
                atributo: 'nome',
                descricao: 'Nome do funcionário',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Informações do posto',
            descricao: 'Esse endpoint é responsável por pegar as informações de um posto integrado.',
            rota: 'pegar-dados-posto/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-dados-posto/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Posto não encontrado.' },
            mensagem_sucesso: {
              cnpj: '98834148000126',
              nome_fantasia: 'Posto Teste',
              litragem_2_casas: false,
              tipo_desconto: 2,
              truncamento_valor: false,
              truncamento_combustivel: false,
              cashposto: false
            },
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'cnpj',
                descricao: 'CNPJ do posto.',
                tipo: 'Texto'
              },
              {
                atributo: 'nome_fantasia',
                descricao: 'Nome Fantasia do posto.',
                tipo: 'Texto'
              },
              {
                atributo: 'litragem_2_casas',
                descricao: 'Informa se os preços dos combustíveis utilizam duas casas decimais (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'tipo_desconto',
                descricao: 'Informa o tipo de desconto aplicado. Os tipos de descontos Existentes são 1 - Aplicado ao Valor Final da Compra. 2 - Aplicado ao Valor do Item Vendido.',
                tipo: 'Inteiro'
              },
              {
                atributo: 'truncamento_valor',
                descricao: 'Informa se é aplicado trucamento (true) ou arrebondamento (false) ao valor final da compra.',
                tipo: 'Booleano'
              },
              {
                atributo: 'truncamento_combustivel',
                descricao: 'Informa se é aplicado trucamento (true) ou arrebondamento (false) ao valor dos combustíveis.',
                tipo: 'Booleano'
              },
              {
                atributo: 'cashposto',
                descricao: 'Informa se o posto está oferecendo cashback do posto (cashposto) no lugar de desconto.',
                tipo: 'Booleano'
              }
            ]
          },
          {
            titulo: 'Pegar Combustíveis',
            descricao: 'Esse endpoint é responsável por pegar os combustíveis de um posto integrado.',
            rota: 'pegar-combustiveis/',
            url: 'https://external.apetrus.com.br/automacao/pegar-combustiveis/',
            id: undefined,
            api: '/automacao/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Posto não encontrado.' },
            mensagem_sucesso: [
              {
                preco: '5.470',
                combustivel: 'Etanol Comum',
                referencia: 'etanol'
              }
            ],
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as informações dos combustíveis. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'preco',
                descricao: 'Informa o CNPJ do posto a qual o combustível pertence.',
                tipo: 'Booleano'
              },
              {
                atributo: 'combustivel',
                descricao: 'Informa o nome do combustível.',
                tipo: 'Texto'
              },
              {
                atributo: 'referencia',
                descricao: 'Informa a referencia do combustível.',
                tipo: 'Número de ponto flutuante'
              }
            ]
          },
          {
            titulo: 'Pegar Ofertas',
            descricao: 'Esse endpoint é responsável por pegar as ofertas de um posto integrado, através da referência do combustível.',
            rota: 'pegar-ofertas/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-ofertas/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Posto não encontrado.' },
            mensagem_sucesso: [
              {
                promocao: false,
                percentual_desconto: 7,
                para_assinantes: false,
                data_fim: '2022-11-30',
                combustiveis: [
                  {
                    preco: 5.65,
                    combustivel: 'Gasolina Comum',
                    referencia: 'gasolina',
                    data_fim: '2022-12-31'
                  }
                ]
              }
            ],
            table: {
              items: [
                {
                  atributo: 'referencia',
                  descricao: 'Esse atributo é responsável por informar a referência do combustível com oferta ou promoção',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'gasolina'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter as ofertas. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                },
                {
                  atributo: 'promocao',
                  descricao: 'Esse atributo é responsável por informar se será obtido ofetas (promocao=false) ou promoções (promocao=false).',
                  obrigatorio: 'Não',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: 'true'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'promocao',
                descricao: 'Informa se é uma promoção (true) ou uma oferta comum (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'percentual_desconto',
                descricao: 'Informa o percentual de desconto oferecido.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'para_assinantes',
                descricao: 'Informa se é exclusivo para assinantes do programa de assinatura do posto (true) ou não',
                tipo: 'Booleano'
              },
              {
                atributo: 'data_fim',
                descricao: 'Informa a data de encerramento da promoção. OBS: Não existe para ofertas comuns.',
                tipo: 'Data'
              },
              {
                atributo: 'combustiveis',
                descricao: 'Informa o combustível relacionado a oferta, caso haja, caso não a oferta é válida para todos os combustíveis.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Convênios',
            descricao: 'Esse endpoint é responsável por pegar os convênios de um posto integrado.',
            rota: 'pegar-convenios/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-convenios/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Posto não encontrado.' },
            mensagem_sucesso: [
              {
                combustivel: 'Etanol Comum',
                convenio: 'DESCONTO',
                desconto: 10
              }
            ],
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter os convênios. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '98834148000126'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'desconto',
                descricao: 'Informa o percentual de desconto oferecido.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'convenio',
                descricao: 'Informa o código identificador do convênio.',
                tipo: 'Texto'
              },
              {
                atributo: 'combustivel',
                descricao: 'Informa o combustível relacionado a oferta, caso haja, caso não o convênio é válido para todos os combustíveis.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Pegar Códigos Promocionais',
            descricao: 'Esse endpoint é responsável por pegar os códigos promocionais de um posto integrado.',
            rota: 'pegar-codigospromocionais/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/pegar-codigospromocionais/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Referencia não foi enviado no corpo da requisição.' },
            mensagem_sucesso: [
              {
                codigo: 'DESCONTO',
                desconto: 7,
                data_inicio: '2022-05-09',
                data_fim: null,
                disponivel: true,
                combustivel: 'Etanol Comum'
              }
            ],
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja obter os códigos promocionais. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  valor: '98834148000126'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'codigo',
                descricao: 'Informa o código identificador do código promocional.',
                tipo: 'Texto'
              },
              {
                atributo: 'desconto',
                descricao: 'Informa o percentual de desconto oferecido.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'data_inicio',
                descricao: 'Informa a data de inicio do código promocional.',
                tipo: 'Data'
              },
              {
                atributo: 'data_fim',
                descricao: 'Informa a data de encerramento do código promocional.',
                tipo: 'Data'
              },
              {
                atributo: 'disponivel',
                descricao: 'Informa se o código promocional está disponível para o uso do cliente.',
                tipo: 'Booleano'
              },
              {
                atributo: 'combustivel',
                descricao: 'Informa o combustível relacionado a oferta, caso haja, caso não o convênio é válido para todos os combustíveis.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Alterar Preço do Combustível',
            descricao: 'Esse endpoint é responsável por alterar o preço de um combustível para um posto integrado.',
            rota: 'alterar-preco/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/alterar-preco/',
            nome_lista: undefined,
            body: [
              'posto: 98834148000126',
              'referencia: gasolina',
              'valor: 5.35'
            ],
            method: 'POST',
            mensagem_erro: { erro: 'Referencia não foi enviado no corpo da requisição.' },
            mensagem_sucesso: {
              sucesso: 'Combustível cadastrado com sucesso.'
            },
            table: {
              items: [
                {
                  atributo: 'referencia',
                  descricao: 'Esse atributo é responsável por informar a referência do combustível que se deseja cadastrar.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'gasolina'
                },
                {
                  atributo: 'posto',
                  descricao: 'Esse atributo é responsável por informar o CNPJ do posto que se deseja cadastrar o combustível. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '98834148000126'
                },
                {
                  atributo: 'valor',
                  descricao: 'Esse atributo é responsável por informar o valor que custará o combustível.',
                  obrigatorio: 'SIM',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: '5.35'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'sucesso',
                descricao: 'Mensagem de sucesso.',
                tipo: 'Texto'
              },
              {
                atributo: 'erro',
                descricao: 'Mensagem de error.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Cadastrar Posto',
            descricao: 'Esse endpoint é responsável por realizar o cadastro de um novo posto.',
            rota: 'cadastrar-posto/',
            id: undefined,
            api: '/automacao/',
            url: 'https://external.apetrus.com.br/automacao/cadastrar-posto/',
            nome_lista: undefined,
            body: [
              'cnpj: 98834148000126',
              'nome_fantasia: Apetrus',
              'razao_social: Apetrus Soluções Tecnologicas',
              'latitude: 5.8459618',
              'longitude: -35.2050968',
              'tipo_visualizacao_precos_combustiveis: 1',
              'tipo_desconto: 1',
              'litragem_2_casas: false',
              'truncamento_valor: false',
              'truncamento_combustivel: false',
              'cep: 59000000',
              'numero: 345',
              'estado: RN',
              'cidade: Natal',
              'logradouro: AV. Engenheiro Roberto Freire',
              'bairro: Capim Macio'
            ],
            method: 'POST',
            mensagem_erro: { erro: 'CNPJ não foi enviado na requisição' },
            mensagem_sucesso: {
              sucesso: 'Posto cadastrado com sucesso.'
            },
            table: {
              items: [
                {
                  atributo: 'cnpj',
                  descricao: 'CNPJ do posto que se deseja cadastrar. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '98834148000126'
                },
                {
                  atributo: 'nome_fantasia',
                  descricao: 'Nome Fantasia do posto que se deseja cadastrar. Esse atributo deve conter no máximo 100 caracteres.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Apetrus'
                },
                {
                  atributo: 'razao_social',
                  descricao: 'Razão Social do posto que se deseja cadastrar. Esse atributo deve conter no máximo 100 caracteres.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Apetrus Soluções Tecnologicas'
                },
                {
                  atributo: 'latitude',
                  descricao: 'Latitude (Coordenada geográfica) do posto que se deseja cadastrar.',
                  obrigatorio: 'SIM',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: '-5.8459618'
                },
                {
                  atributo: 'longitude',
                  descricao: 'Longitude (Coordenada geográfica) do posto que se deseja cadastrar.',
                  obrigatorio: 'SIM',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: '-35.2050968'
                },
                {
                  atributo: 'tipo_visualizacao_precos_combustiveis',
                  descricao: 'Esse atributo informa a API quem pode ver os preços dos combustíveis no aplicativo Apetrus. Os tipos de visualização existentes são: 0 -> Ninguém visualiza. 1 -> Todos visualizam. 2 -> Apenas usuários dentro da área do posto, 4 -> Apenas usuários que já compraram ao menos uma vez no posto',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'tipo_desconto',
                  descricao: 'Esse atributo informa a API qual o tipo de desconto aplicado. Os tipos de descontos Existentes são 1 - Aplicado ao Valor Final da Compra. 2 - Aplicado ao Valor do Item Vendido.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 1
                },
                {
                  atributo: 'litragem_2_casas',
                  descricao: 'Esse atributo informa a API se o combustível é mostrado com duas casas decimais (true) ou com três casas descimais (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: false
                },
                {
                  atributo: 'truncamento_valor',
                  descricao: 'Esse atributo informa a API se o valor final da compra pode ser arredondado (false) ou truncado (true).',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: false
                },
                {
                  atributo: 'truncamento_combustivel',
                  descricao: 'Esse atributo informa a API se o do combustível pode ser arredondado (false) ou truncado (true).',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: false
                },
                {
                  atributo: 'cep',
                  descricao: 'Esse atributo informa a API o CEP do posto que se deseja inserir.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '59000000'
                },
                {
                  atributo: 'numero',
                  descricao: 'Esse atributo informa a API o número do local do posto que se deseja inserir.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 345
                },
                {
                  atributo: 'estado',
                  descricao: 'Esse atributo informa a API o estado do local do posto que se deseja inserir. Deve-se enviar apenas a sigla do estado. Ex: RN',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'RN'
                },
                {
                  atributo: 'cidade',
                  descricao: 'Esse atributo informa a API o cidade do local do posto que se deseja inserir.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Natal'
                },
                {
                  atributo: 'logradouro',
                  descricao: 'Esse atributo informa a API o logradouro do local do posto que se deseja inserir.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'AV. Engenheiro Roberto Freire'
                },
                {
                  atributo: 'bairro',
                  descricao: 'Esse atributo informa a API o bairro do local do posto que se deseja inserir.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Capim Macio'
                },
                {
                  atributo: 'complemento',
                  descricao: 'Esse atributo informa a API o complemento do local do posto que se deseja inserir.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'Predio A'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'sucesso',
                descricao: 'Mensagem de sucesso.',
                tipo: 'Texto'
              },
              {
                atributo: 'erro',
                descricao: 'Mensagem de error.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Cadastrar Oferta ou Promoção',
            descricao: 'Esse endpoint é responsável por realizar o cadastro de uma oferta ou promoção. ',
            url: 'https://external.apetrus.com.br/automacao/cadastrar-oferta/',
            nome_lista: undefined,
            body: [
              'posto: 98834148000126',
              'data_inicio: 15/04/2024',
              'data_fim: 15/04/2024',
              'promocao: true',
              'percentual_desconto: 2',
              'para_rede: false',
              'para_assinantes: false'
            ],
            method: 'POST',
            mensagem_erro: 'Não existe um combustível cadastrado com essa referencia.',
            mensagem_sucesso: {
              texto: 'Oferta cadastrada com sucesso.',
              results: undefined
            },
            table: {
              items: [
                {
                  atributo: 'posto',
                  descricao: 'CNPJ do posto que se deseja cadastrar a oferta. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '98834148000126'
                },
                {
                  atributo: 'data_inicio',
                  descricao: 'Data que a oferta ou promoção passará a ser válida.',
                  obrigatorio: 'SIM',
                  tipo: 'Data',
                  inserido: true,
                  valor: '15/04/2024'
                },
                {
                  atributo: 'data_fim',
                  descricao: 'Data que a promoção deixará de ser válida. Esse campo é obrigatório apenas para promoções.',
                  obrigatorio: 'SIM',
                  tipo: 'Data',
                  inserido: true,
                  valor: '15/04/2024'
                },
                {
                  atributo: 'promocao',
                  descricao: 'Esse atributo informa se os dados enviados pertencem (true) ou não (false) a uma promoção.',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: 'true'
                },
                {
                  atributo: 'percentual_desconto',
                  descricao: 'Esse atributo informa o percentual de desconto oferecido aos clientes, através da oferta ou promoção.',
                  obrigatorio: 'SIM',
                  tipo: 'Inteiro',
                  inserido: true,
                  valor: '2'
                },
                {
                  atributo: 'para_rede',
                  descricao: 'Esse atributo informa a API se a oferta ou promoção pertence a rede de postos, cujo o posto é pertencente (true), ou não pertence a rede de postos (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: false
                },
                {
                  atributo: 'para_assinantes',
                  descricao: 'Esse atributo informa a API se a oferta ou promoção é destinada para os assinantes de programa de assinatura do posto (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: false
                },
                {
                  atributo: 'combustiveis_nao_ofertados',
                  descricao: 'Esse atributo informa a API quais combustiveis não farão parte da oferta ou promoção.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista',
                  inserido: false,
                  valor: ['gasolina', 'etanol']
                },
                {
                  atributo: 'referencia',
                  descricao: 'Esse atributo é responsável por informar a referência do combustível da promoção ou oferta que se deseja cadastrar.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'diesel'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'sucesso',
                descricao: 'Mensagem de sucesso.',
                tipo: 'Texto'
              },
              {
                atributo: 'erro',
                descricao: 'Mensagem de error.',
                tipo: 'Texto'
              }
            ]
          }
        ],
        combustiveis: true,
        tipos_cambio: false,
        tipos_direcao: false,
        tipos_veiculo: false,
        bandeiras: true,
        tipos_pagamento: true
      },
      {
        nome: 'Veículos',
        api: '/veiculos/',
        endpoints: [
          {
            titulo: 'Pegar Veículos',
            descricao: 'Esse endpoint é responsável por pegar uma lista de veículos de uma empresa revendedora.',
            rota: undefined,
            id: undefined,
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                identificacao: 'AAA1010',
                marca: 'FORD',
                modelo: 'Fiesta',
                cor: 'Preta',
                combustiveis: [
                  'gasolina'
                ],
                tipo_veiculo: 'Carro',
                id_veiculo: 632,
                ano_fabricacao: '2010/2011',
                aVenda: true,
                valor: '84900.00',
                usado: true,
                cambio: 'manual',
                direcao: 'hidráulica',
                quilometragem: 84000,
                descricao: null,
                qtd_portas: null,
                quitando: false,
                ipva_pago: false,
                com_multa: false,
                de_leilao: false,
                unico_dono: false,
                com_manual: false,
                com_garantia: false,
                revisoes_feitas: false,
                chave_reserva: false,
                qtd_estoque: null,
                motor: '1.0',
                cilindradas: null,
                opcionais: [
                  {
                    codigo: 1,
                    nome: 'Vidro Elétrico',
                    referencia: 'vidro_eletrico'
                  }
                ],
                fotos: [
                  {
                    id: 128,
                    nome: 'Imagem da Frente',
                    url: 'https://firebasestorage.googleapis.com/v0/b/apetrus-testes.appspot.com/o/empresas%2F87909075000178%2Fimagens%2Ffrontal_AAA1010.png?alt=media&token=24448f02-bb4d-4bdf-9e9b-8b4d244831a4',
                    externa: false
                  }
                ]
              }
            ],
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '87909075000178'
                },
                {
                  atributo: 'aVenda',
                  descricao: 'Esse atributo é responsável por informar se os veículos estão a venda (true) ou não (false).',
                  obrigatorio: 'Não',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: 'true'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo. Deve-se enviar sem caracteres especiais.',
                  obrigatorio: 'Não',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'XXX9090'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'identificacao',
                descricao: 'Refere-se a placa do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'marca',
                descricao: 'Refere-se a marca do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'modelo',
                descricao: 'Refere-se ao modelo do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'cor',
                descricao: 'Refere-se a cor do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'tipo_veiculo',
                descricao: 'Refere-se ao tipo do veículo do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'fotos',
                descricao: 'Refere-se as fotos do veículo.',
                tipo: 'Lista'
              },
              {
                atributo: 'foto',
                descricao: 'Refere-se a foto do veículo contida na lista de fotos.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'foto.nome',
                descricao: 'Refere-se ao nome da região do veículo de onde a foto foi tirada.',
                tipo: 'Texto'
              },
              {
                atributo: 'foto.url',
                descricao: 'Refere-se a URL da foto do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'foto.externa',
                descricao: 'Informa se a imagem foi salva no sistema Apetrus (false) ou não (true).',
                tipo: 'Texto'
              },
              {
                atributo: 'id_veiculo',
                descricao: 'Refere-se ao identificador do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'ano_fabricacao',
                descricao: 'Refere-se ao ano de fabricação do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'aVenda',
                descricao: 'Informa se o veículo está a venda (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'valor',
                descricao: 'Refere-se ao valor do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'usado',
                descricao: 'Informa se o veículo é usado (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'cambio',
                descricao: 'Refere-se ao tipo de câmbio do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'direcao',
                descricao: 'Refere-se ao tipo de direção do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'quilometragem',
                descricao: 'Refere-se a quilometragem do veículo.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'opcionais',
                descricao: 'Refere-se aos opcionais do veículo',
                tipo: 'Lista'
              },
              {
                atributo: 'opcional',
                descricao: 'Refere-se a um opcional contido na lista de opcionais',
                tipo: 'Conjunto'
              },
              {
                atributo: 'opcional.nome',
                descricao: 'Refere-se ao nome do opcional do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'opcional.referencia',
                descricao: 'Refere-se a referencia do opcional do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'opcional.codigo',
                descricao: 'Refere-se ao código identificador do opcional do veículo',
                tipo: 'Inteiro'
              },
              {
                atributo: 'quitado',
                descricao: 'Informa já foi quitado (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'ipva_pago',
                descricao: 'Informa se o pussui IPVA pago (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_multa',
                descricao: 'Informa se o pussui multa (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'de_leilao',
                descricao: 'Informa se o foi pego em um leilão (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'unico_dono',
                descricao: 'Informa se o ter um único dono (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_manual',
                descricao: 'Informa se possui manual (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_garantia',
                descricao: 'Informa possui garantia (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'revisoes_feitas',
                descricao: 'Informa se já está com as revisões feitas (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'chave_reserva',
                descricao: 'Informa se possui chave reserva (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'qtd_estoque',
                descricao: 'Informa a quantidade em estoque (Para veículos novos).',
                tipo: 'Número'
              },
              {
                atributo: 'qtd_portas',
                descricao: 'Informa a quantidade de portas.',
                tipo: 'Número'
              },
              {
                atributo: 'descricao',
                descricao: 'Refere-se a descrição do veículo (Pequeno comentário sobre algumas caracteristicas).',
                tipo: 'Texto'
              },
              {
                atributo: 'motor',
                descricao: 'Refere-se a capacidade cúbica dos cilindros do motor.',
                tipo: 'Texto'
              },
              {
                atributo: 'cilindradas',
                descricao: 'Refere-se ao indicador importante da potência e desempenho da moto.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Cadastrar Veículo',
            descricao: 'Esse endpoint é responsável por cadastrar um veículo de uma empresa revendedora.',
            rota: undefined,
            id: undefined,
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/',
            nome_lista: undefined,
            body: [
              'empresa: 87909075000178',
              'identificacao: XXX9090',
              'marca: BMW',
              'modelo: Car',
              'cor: Preta',
              'tipo_veiculo: carro',
              'ano_fabricacao: 2012/2013',
              'aVenda: true',
              'valor: 100000.00',
              'usado: true',
              'quilometragem: 500.00',
              'qtd_portas: 4',
              'combustiveis: ["gasolina", "gnv"]'
            ],
            method: 'POST',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              id_veiculo: 677
            },
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '87909075000178'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo. Deve-se enviar sem caracteres especiais.',
                  obrigatorio: 'usado (SIM) novo (NÃO)',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'XXX9090'
                },
                {
                  atributo: 'marca',
                  descricao: 'Refere-se a marca do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'BMW'
                },
                {
                  atributo: 'modelo',
                  descricao: 'Refere-se ao modelo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'cor',
                  descricao: 'Refere-se a cor do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'tipo_veiculo',
                  descricao: 'Refere-se ao tipo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'carro'
                },
                {
                  atributo: 'ano_fabricacao',
                  descricao: 'Refere-se ao ano de fabricação do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '2012/2013'
                },
                {
                  atributo: 'aVenda',
                  descricao: 'Informa se o veículo ficará a venda (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'valor',
                  descricao: 'Refere-se ao valor do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '100000.00'
                },
                {
                  atributo: 'usado',
                  descricao: 'Informa se o veículo é usado (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'cambio',
                  descricao: 'Refere-se ao tipo de câmbio do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'direcao',
                  descricao: 'Refere-se ao tipo de direção do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'quilometragem',
                  descricao: 'Refere-se a quilometragem do veículo.',
                  obrigatorio: 'SIM, caso usado',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: 500.00
                },
                {
                  atributo: 'combustiveis',
                  descricao: 'Refere-se aos combustíveis do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Lista<Texto>',
                  inserido: true,
                  valor: ['gasolina', 'gnv']
                },
                {
                  atributo: 'opcionais',
                  descricao: 'Refere-se aos opcionais do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Inteiro>',
                  inserido: false,
                  valor: [1, 2]
                },
                {
                  atributo: 'fotos',
                  descricao: 'Refere-se as fotos do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Conjunto>',
                  inserido: false,
                  valor: [
                    {
                      id: 128,
                      nome: 'Imagem da Frente',
                      url: 'https://firebasestorage.googleapis.com/v0/b/apetrus-testes.appspot.com/o/empresas%2F87909075000178%2Fimagens%2Ffrontal_AAA1010.png?alt=media&token=24448f02-bb4d-4bdf-9e9b-8b4d244831a4',
                      externa: false
                    }
                  ]
                },
                {
                  atributo: 'quitado',
                  descricao: 'Informa já foi quitado (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'ipva_pago',
                  descricao: 'Informa se o pussui IPVA pago (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_multa',
                  descricao: 'Informa se o pussui multa (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'de_leilao',
                  descricao: 'Informa se o foi pego em um leilão (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'unico_dono',
                  descricao: 'Informa se o ter um único dono (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_manual',
                  descricao: 'Informa se possui manual (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_garantia',
                  descricao: 'Informa possui garantia (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'revisoes_feitas',
                  descricao: 'Informa se já está com as revisões feitas (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'chave_reserva',
                  descricao: 'Informa se possui chave reserva (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'qtd_estoque',
                  descricao: 'Informa a quantidade em estoque (Para veículos novos).',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'qtd_portas',
                  descricao: 'Informa a quantidade de portas.',
                  obrigatorio: 'SIM para carros',
                  tipo: 'Número',
                  inserido: true,
                  valor: 4
                },
                {
                  atributo: 'descricao',
                  descricao: 'Refere-se a descrição do veículo (Pequeno comentário sobre algumas caracteristicas).',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'Lorem Ipsum'
                },
                {
                  atributo: 'motor',
                  descricao: 'Refere-se a capacidade cúbica dos cilindros do motor.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1.0'
                },
                {
                  atributo: 'cilindradas',
                  descricao: 'Refere-se ao indicador importante da potência e desempenho da moto.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '150'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'id_veiculo',
                descricao: 'Refere-se ao identificador do veículo.',
                tipo: 'Número'
              }
            ]
          },
          {
            titulo: 'Atualizar Veículo',
            descricao: 'Esse endpoint é responsável por cadastrar um veículo de uma empresa revendedora.',
            rota: undefined,
            id: '677/',
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/',
            nome_lista: undefined,
            body: [
              'empresa: 87909075000178',
              'identificacao: XXX9090',
              'marca: BMW',
              'modelo: Car',
              'cor: Preta',
              'tipo_veiculo: carro',
              'ano_fabricacao: 2012/2013',
              'aVenda: true',
              'valor: 100000.00',
              'usado: true',
              'quilometragem: 500.00',
              'qtd_portas: 4'
            ],
            method: 'PUT',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              id_veiculo: 677
            },
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '87909075000178'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo. Deve-se enviar sem caracteres especiais.',
                  obrigatorio: 'usado (SIM) novo (NÃO)',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'XXX9090'
                },
                {
                  atributo: 'marca',
                  descricao: 'Refere-se a marca do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'BMW'
                },
                {
                  atributo: 'modelo',
                  descricao: 'Refere-se ao modelo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'cor',
                  descricao: 'Refere-se a cor do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'tipo_veiculo',
                  descricao: 'Refere-se ao tipo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'carro'
                },
                {
                  atributo: 'ano_fabricacao',
                  descricao: 'Refere-se ao ano de fabricação do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '2012/2013'
                },
                {
                  atributo: 'aVenda',
                  descricao: 'Informa se o veículo ficará a venda (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'valor',
                  descricao: 'Refere-se ao valor do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '100000.00'
                },
                {
                  atributo: 'usado',
                  descricao: 'Informa se o veículo é usado (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'cambio',
                  descricao: 'Refere-se ao tipo de câmbio do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'direcao',
                  descricao: 'Refere-se ao tipo de direção do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'opcionais',
                  descricao: 'Refere-se aos opcionais do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Inteiro>',
                  inserido: false,
                  valor: [1, 2]
                },
                {
                  atributo: 'fotos',
                  descricao: 'Refere-se as fotos do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Conjunto>',
                  inserido: false,
                  valor: [
                    {
                      nome: 'Imagem da Frente',
                      url: 'https://firebasestorage.googleapis.com/v0/b/apetrus-testes.appspot.com/o/empresas%2F87909075000178%2Fimagens%2Ffrontal_AAA1010.png?alt=media&token=24448f02-bb4d-4bdf-9e9b-8b4d244831a4'
                    }
                  ]
                },
                {
                  atributo: 'quilometragem',
                  descricao: 'Refere-se a quilometragem do veículo.',
                  obrigatorio: 'SIM, caso usado',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: 500.00
                },
                {
                  atributo: 'quitado',
                  descricao: 'Informa já foi quitado (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'ipva_pago',
                  descricao: 'Informa se o pussui IPVA pago (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_multa',
                  descricao: 'Informa se o pussui multa (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'de_leilao',
                  descricao: 'Informa se o foi pego em um leilão (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'unico_dono',
                  descricao: 'Informa se o ter um único dono (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_manual',
                  descricao: 'Informa se possui manual (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_garantia',
                  descricao: 'Informa possui garantia (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'revisoes_feitas',
                  descricao: 'Informa se já está com as revisões feitas (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'chave_reserva',
                  descricao: 'Informa se possui chave reserva (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'qtd_estoque',
                  descricao: 'Informa a quantidade em estoque (Para veículos novos).',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'qtd_portas',
                  descricao: 'Informa a quantidade de portas.',
                  obrigatorio: 'SIM para carros',
                  tipo: 'Número',
                  inserido: true,
                  valor: 4
                },
                {
                  atributo: 'descricao',
                  descricao: 'Refere-se a descrição do veículo (Pequeno comentário sobre algumas caracteristicas).',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'Lorem Ipsum'
                },
                {
                  atributo: 'motor',
                  descricao: 'Refere-se a capacidade cúbica dos cilindros do motor.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1.0'
                },
                {
                  atributo: 'cilindradas',
                  descricao: 'Refere-se ao indicador importante da potência e desempenho da moto.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '150'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'id_veiculo',
                descricao: 'Refere-se ao identificador do veículo.',
                tipo: 'Número'
              }
            ]
          },
          {
            titulo: 'Excluir Veículo',
            descricao: 'Esse endpoint é responsável por cadastrar um veículo de uma empresa revendedora.',
            rota: undefined,
            id: '677/',
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/',
            nome_lista: undefined,
            body: [
              'empresa: 87909075000178'
            ],
            method: 'DELETE',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              sucesso: 'Veículo apagado com sucesso'
            },
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '87909075000178'
                }
              ]
            },
            itemsReturn: []
          },
          {
            titulo: 'Pegar Veículos Anunciados',
            descricao: 'Esse endpoint é responsável por pegar uma lista de veículos anunciados para uma empresa revendedora.',
            rota: 'anuncios/',
            id: undefined,
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/anuncios/',
            nome_lista: undefined,
            body: [],
            method: 'GET',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: [
              {
                identificacao: 'AAA1010',
                marca: 'FORD',
                modelo: 'Fiesta',
                cor: 'Preta',
                combustiveis: [
                  'gasolina'
                ],
                tipo_veiculo: 'Carro',
                id_veiculo: 632,
                ano_fabricacao: '2010/2011',
                aVenda: true,
                valor: '84900.00',
                usado: true,
                cambio: 'manual',
                direcao: 'hidráulica',
                quilometragem: 84000,
                descricao: null,
                qtd_portas: null,
                quitando: false,
                ipva_pago: false,
                com_multa: false,
                de_leilao: false,
                unico_dono: false,
                com_manual: false,
                com_garantia: false,
                revisoes_feitas: false,
                chave_reserva: false,
                qtd_estoque: null,
                motor: '1.0',
                cilindradas: null,
                opcionais: [
                  {
                    codigo: 1,
                    nome: 'Vidro Elétrico',
                    referencia: 'vidro_eletrico'
                  }
                ],
                fotos: [
                  {
                    id: 128,
                    nome: 'Imagem da Frente',
                    url: 'https://firebasestorage.googleapis.com/v0/b/apetrus-testes.appspot.com/o/empresas%2F87909075000178%2Fimagens%2Ffrontal_AAA1010.png?alt=media&token=24448f02-bb4d-4bdf-9e9b-8b4d244831a4',
                    externa: false
                  }
                ]
              }
            ],
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '87909075000178'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo. Deve-se enviar sem caracteres especiais.',
                  obrigatorio: 'Não',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'true'
                },
                {
                  atributo: 'tipo_veiculo',
                  descricao: 'Esse atributo é responsável por informar o ID do tipo do veículo.',
                  obrigatorio: 'Não',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'carro'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'identificacao',
                descricao: 'Refere-se a placa do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'marca',
                descricao: 'Refere-se a marca do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'modelo',
                descricao: 'Refere-se ao modelo do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'cor',
                descricao: 'Refere-se a cor do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'tipo_veiculo',
                descricao: 'Refere-se ao tipo do veículo do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'id_veiculo',
                descricao: 'Refere-se ao identificador do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'fotos',
                descricao: 'Refere-se as fotos do veículo.',
                tipo: 'Lista'
              },
              {
                atributo: 'foto',
                descricao: 'Refere-se a foto do veículo contida na lista de fotos.',
                tipo: 'Conjunto'
              },
              {
                atributo: 'foto.nome',
                descricao: 'Refere-se ao nome da região do veículo de onde a foto foi tirada.',
                tipo: 'Texto'
              },
              {
                atributo: 'foto.url',
                descricao: 'Refere-se a URL da foto do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'foto.externa',
                descricao: 'Informa se a imagem foi salva no sistema Apetrus (false) ou não (true).',
                tipo: 'Texto'
              },
              {
                atributo: 'ano_fabricacao',
                descricao: 'Refere-se ao ano de fabricação do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'aVenda',
                descricao: 'Informa se o veículo está a venda (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'valor',
                descricao: 'Refere-se ao valor do veículo.',
                tipo: 'Texto'
              },
              {
                atributo: 'usado',
                descricao: 'Informa se o veículo é usado (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'cambio',
                descricao: 'Refere-se ao tipo de câmbio do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'direcao',
                descricao: 'Refere-se ao tipo de direção do veículo.',
                tipo: 'Número'
              },
              {
                atributo: 'quilometragem',
                descricao: 'Refere-se a quilometragem do veículo.',
                tipo: 'Número de ponto flutuante'
              },
              {
                atributo: 'opcionais',
                descricao: 'Refere-se aos opcionais do veículo',
                tipo: 'Lista'
              },
              {
                atributo: 'opcional',
                descricao: 'Refere-se a um opcional contido na lista de opcionais',
                tipo: 'Conjunto'
              },
              {
                atributo: 'opcional.nome',
                descricao: 'Refere-se ao nome do opcional do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'opcional.referencia',
                descricao: 'Refere-se a referencia do opcional do veículo',
                tipo: 'Texto'
              },
              {
                atributo: 'opcional.codigo',
                descricao: 'Refere-se ao código identificador do opcional do veículo',
                tipo: 'Inteiro'
              },
              {
                atributo: 'quitado',
                descricao: 'Informa já foi quitado (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'ipva_pago',
                descricao: 'Informa se o pussui IPVA pago (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_multa',
                descricao: 'Informa se o pussui multa (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'de_leilao',
                descricao: 'Informa se o foi pego em um leilão (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'unico_dono',
                descricao: 'Informa se o ter um único dono (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_manual',
                descricao: 'Informa se possui manual (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'com_garantia',
                descricao: 'Informa possui garantia (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'revisoes_feitas',
                descricao: 'Informa se já está com as revisões feitas (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'chave_reserva',
                descricao: 'Informa se possui chave reserva (true) ou não (false).',
                tipo: 'Booleano'
              },
              {
                atributo: 'qtd_estoque',
                descricao: 'Informa a quantidade em estoque (Para veículos novos).',
                tipo: 'Número'
              },
              {
                atributo: 'qtd_portas',
                descricao: 'Informa a quantidade de portas.',
                tipo: 'Número'
              },
              {
                atributo: 'descricao',
                descricao: 'Refere-se a descrição do veículo (Pequeno comentário sobre algumas caracteristicas).',
                tipo: 'Texto'
              },
              {
                atributo: 'motor',
                descricao: 'Refere-se a capacidade cúbica dos cilindros do motor.',
                tipo: 'Texto'
              },
              {
                atributo: 'cilindradas',
                descricao: 'Refere-se ao indicador importante da potência e desempenho da moto.',
                tipo: 'Texto'
              }
            ]
          },
          {
            titulo: 'Cadastrar Vários Veículos',
            descricao: 'Esse endpoint é responsável por cadastrar vários veículos de uma empresa revendedora.',
            rota: 'multiplos/',
            id: undefined,
            api: '/veiculos/',
            url: 'https://external.apetrus.com.br/veiculos/multiplos/',
            nome_lista: 'veiculos',
            body: [
              'identificacao: XXX9090',
              'marca: BMW',
              'modelo: Car',
              'cor: Preta',
              'tipo_veiculo: carro',
              'ano_fabricacao: 2012/2013',
              'aVenda: true',
              'valor: 100000.00',
              'usado: true',
              'quilometragem: 500.00',
              'qtd_portas: 4',
              'combustiveis: ["gasolina", "gnv"]'
            ],
            method: 'POST',
            mensagem_erro: { erro: 'Você não possui autorização' },
            mensagem_sucesso: {
              numero_veiculos_nao_cadastrados: 0
            },
            table: {
              items: [
                {
                  atributo: 'empresa',
                  descricao: 'Esse atributo é responsável por informar o CNPJ da empresa que se deseja obter os veículos. Esse atributo deve conter 14 caracteres numéricos.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '87909075000178'
                },
                {
                  atributo: 'identificacao',
                  descricao: 'Esse atributo é responsável por informar a placa do veículo. Deve-se enviar sem caracteres especiais.',
                  obrigatorio: 'usado (SIM) novo (NÃO)',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'XXX9090'
                },
                {
                  atributo: 'marca',
                  descricao: 'Refere-se a marca do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'BMW'
                },
                {
                  atributo: 'modelo',
                  descricao: 'Refere-se ao modelo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'cor',
                  descricao: 'Refere-se a cor do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'Car'
                },
                {
                  atributo: 'tipo_veiculo',
                  descricao: 'Refere-se ao tipo do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: 'carro'
                },
                {
                  atributo: 'opcionais',
                  descricao: 'Refere-se aos opcionais do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Inteiro>',
                  inserido: false,
                  valor: [1, 2]
                },
                {
                  atributo: 'fotos',
                  descricao: 'Refere-se as fotos do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Lista<Conjunto>',
                  inserido: false,
                  valor: [
                    {
                      nome: 'Imagem da Frente',
                      url: 'https://firebasestorage.googleapis.com/v0/b/apetrus-testes.appspot.com/o/empresas%2F87909075000178%2Fimagens%2Ffrontal_AAA1010.png?alt=media&token=24448f02-bb4d-4bdf-9e9b-8b4d244831a4'
                    }
                  ]
                },
                {
                  atributo: 'ano_fabricacao',
                  descricao: 'Refere-se ao ano de fabricação do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '2012/2013'
                },
                {
                  atributo: 'aVenda',
                  descricao: 'Informa se o veículo ficará a venda (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'valor',
                  descricao: 'Refere-se ao valor do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: true,
                  valor: '100000.00'
                },
                {
                  atributo: 'usado',
                  descricao: 'Informa se o veículo é usado (true) ou não (false).',
                  obrigatorio: 'SIM',
                  tipo: 'Booleano',
                  inserido: true,
                  valor: true
                },
                {
                  atributo: 'combustiveis',
                  descricao: 'Refere-se aos combustíveis do veículo.',
                  obrigatorio: 'SIM',
                  tipo: 'Lista<Texto>',
                  inserido: true,
                  valor: ['gasolina', 'gnv']
                },
                {
                  atributo: 'cambio',
                  descricao: 'Refere-se ao tipo de câmbio do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'direcao',
                  descricao: 'Refere-se ao tipo de direção do veículo.',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'quilometragem',
                  descricao: 'Refere-se a quilometragem do veículo.',
                  obrigatorio: 'SIM, caso usado',
                  tipo: 'Número de ponto flutuante',
                  inserido: true,
                  valor: 500.00
                },
                {
                  atributo: 'quitado',
                  descricao: 'Informa já foi quitado (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'ipva_pago',
                  descricao: 'Informa se o pussui IPVA pago (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_multa',
                  descricao: 'Informa se o pussui multa (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'de_leilao',
                  descricao: 'Informa se o foi pego em um leilão (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'unico_dono',
                  descricao: 'Informa se o ter um único dono (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_manual',
                  descricao: 'Informa se possui manual (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'com_garantia',
                  descricao: 'Informa possui garantia (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'revisoes_feitas',
                  descricao: 'Informa se já está com as revisões feitas (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'chave_reserva',
                  descricao: 'Informa se possui chave reserva (true) ou não (false).',
                  obrigatorio: 'NÃO',
                  tipo: 'Booleano',
                  inserido: false,
                  valor: true
                },
                {
                  atributo: 'qtd_estoque',
                  descricao: 'Informa a quantidade em estoque (Para veículos novos).',
                  obrigatorio: 'NÃO',
                  tipo: 'Número',
                  inserido: false,
                  valor: 1
                },
                {
                  atributo: 'qtd_portas',
                  descricao: 'Informa a quantidade de portas.',
                  obrigatorio: 'SIM para carros',
                  tipo: 'Número',
                  inserido: true,
                  valor: 4
                },
                {
                  atributo: 'descricao',
                  descricao: 'Refere-se a descrição do veículo (Pequeno comentário sobre algumas caracteristicas).',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: 'Lorem Ipsum'
                },
                {
                  atributo: 'motor',
                  descricao: 'Refere-se a capacidade cúbica dos cilindros do motor.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '1.0'
                },
                {
                  atributo: 'cilindradas',
                  descricao: 'Refere-se ao indicador importante da potência e desempenho da moto.',
                  obrigatorio: 'NÃO',
                  tipo: 'Texto',
                  inserido: false,
                  valor: '150'
                }
              ]
            },
            itemsReturn: [
              {
                atributo: 'id_veiculo',
                descricao: 'Refere-se ao identificador do veículo.',
                tipo: 'Número'
              }
            ]
          }
        ],
        combustiveis: true,
        tipos_cambio: true,
        tipos_direcao: true,
        tipos_veiculo: true,
        bandeiras: false,
        tipos_pagamento: false,
        opcionais: true
      }
    ],
    itemsUrl: [
      {
        ambiente: 'Produção',
        rota_base: 'https://external.apetrus.com.br'
      },
      {
        ambiente: 'Teste',
        rota_base: 'https://teste-external.apetrus.com.br'
      }
    ],
    color: '',
    pagination: {
      page: 1,
      rowsPerPage: 5
    },
    totalItens: 0,
    totalPostos: 0,
    totalPostosPendentes: 0,
    totalPostosConfirmados: 0,
    percentualPostos: 0,
    compras: [],
    bandeiras: [],
    tipos_veiculo: [],
    opcionais: [],
    tipos_cambio: [
      { text: 'Manual', value: 1 },
      { text: 'Automático', value: 2 },
      { text: 'Automatizado', value: 3 },
      { text: 'CVT', value: 4 }
    ],
    tipos_direcao: [
      { text: 'Mecânica', value: 1 },
      { text: 'Hidráulica', value: 2 },
      { text: 'Elétrica', value: 3 },
      { text: 'Eletro-hidráulica', value: 4 }
    ],
    headers: [
      {
        sortable: false
      },
      {
        text: 'Atributo',
        sortable: false
      },
      {
        text: 'Descrição',
        sortable: false
      },
      {
        text: 'Obrigatório',
        sortable: false
      },
      {
        text: 'Tipo',
        sortable: false
      }
    ],
    headersReturn: [
      {
        text: 'Atributo',
        sortable: false
      },
      {
        text: 'Descrição',
        sortable: false
      },
      {
        text: 'Tipo',
        sortable: false
      }
    ],
    carregando: false,
    tab: 0,
    tab2: 0
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    tipos_pagamento () {
      return this.$store.getters.tipos_pagamento
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    },
    rota_producao () {
      return this.itemsUrl[0].rota_base
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    }
  },
  beforeMount () {
    if (this.empresa) {
      if (this.empresa.tipo_empresa === 3) {
        this.pegarCompras()
        this.pegarNumeroPostos()
      }
    }
  },
  watch: {
    empresa (val) {
      if (val !== undefined && val !== null) {
        if (this.empresa.tipo_empresa === 3) {
          this.pegarCompras()
          this.pegarNumeroPostos()
        }
      }
    },
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.pegarCompras(false)
      },
      deep: true
    },
    documentacaoSelecionada (val) {
      if (val) {
        if (val.combustiveis) this.pegarCombustiveis()
        if (val.tipos_veiculo) this.carregarTiposVeiculo()
        if (val.bandeiras) this.pegarBandeirasCartao()
        if (val.tipos_pagamento) this.carregarTiposPagamento()
        if (val.opcionais) this.pegarOpcionaisDoVeiculo()
      }
    }
  },
  methods: {
    carregarTiposVeiculo () {
      this.$store.dispatch('carregarTiposVeiculo')
        .then(json => {
          this.tipos_veiculo = json
        })
    },
    carregarTiposPagamento () {
      this.$store.dispatch('carregarTiposPagamento')
    },
    pegarBandeirasCartao () {
      this.$store.dispatch('pegarBandeirasCartao').then(json => {
        this.bandeiras = json
      })
    },
    copiar () {
      navigator.clipboard.writeText(
        this.descriptografarToken(this.empresa.token)
      )
      this.texto = 'Copiado'
      this.color = 'green'
      const interval = setInterval(() => {
        this.texto = 'Copiar token'
        this.color = ''
        clearInterval(interval)
      }, 3000)
    },
    copiarURL (url) {
      navigator.clipboard.writeText(
        url
      )
      this.texto = 'Copiado'
      this.color = 'green'
      const interval = setInterval(() => {
        this.texto = 'Copiar token'
        this.color = ''
        clearInterval(interval)
      }, 3000)
    },
    listar (data) {
      if (!data) return []
      return Object.keys(data)
    },
    verificarTipo (value) {
      if (value === 'true' || value === 'false') return ''
      if (isNaN(Number(value)) === false) return ''
      return '"'
    },
    descriptografarToken (token) {
      if (token) {
        var decodedStringAtoB = atob(token)
        return decodedStringAtoB
      }
    },
    ocultarToken (token) {
      let teste = ''
      if (this.token) {
        for (var i = 0; i < token.length; i++) {
          teste += '*'
        }
      }
      return teste
    },
    pegarCombustiveis () {
      this.$store.dispatch('carregarCombustiveis')
    },
    async pegarCompras () {
      this.carregando = true
      const data = {
        cnpj: this.empresa.cnpj,
        page: this.pagination.page
      }
      await this.$store.dispatch('pegarComprasAutomacao', data).then(json => {
        this.compras = json.results
        this.totalItens = json.count
      })
      this.carregando = false
    },
    async pegarNumeroPostos () {
      this.carregando = true
      await this.$store.dispatch('numeroPostos', this.empresa.cnpj).then(json => {
        this.totalPostos = json.confirmados + json.pendentes
        if (this.totalPostos > 0) {
          this.percentualPostos = (100 - (json.pendentes * 100 / this.totalPostos)).toFixed()
        } else {
          this.percentualPostos = 0
        }
      })
      this.carregando = false
    },
    addCampo (expansionItems, tableItem) {
      if (expansionItems.method === 'GET') {
        if (!tableItem.inserido) {
          expansionItems.url = expansionItems.url.replace(`?${tableItem.atributo}=${tableItem.valor}`, '')
          expansionItems.url = expansionItems.url.replace(`&${tableItem.atributo}=${tableItem.valor}`, '')
          const teste = /[?]/g
          const resposta = expansionItems.url.search(teste)
          if (resposta === -1) expansionItems.url = expansionItems.url.replace('&', '?')
        } else {
          expansionItems.url = `${expansionItems.url}${expansionItems.url[expansionItems.url.length - 1] === '/' ? '?' : '&'}${tableItem.atributo}=${tableItem.valor}`
        }
      } else {
        let valor = tableItem.valor
        if (Array.isArray(tableItem.valor)) {
          valor = JSON.stringify(valor)
        }
        if (!tableItem.inserido) {
          const indexList = expansionItems.body.indexOf(`${tableItem.atributo}: ${valor}`)
          expansionItems.body.splice(indexList, 1)
        } else {
          expansionItems.body.push(`${tableItem.atributo}: ${valor}`)
        }
      }
    },
    pegarOpcionaisDoVeiculo () {
      this.$store.dispatch('pegarOpcionaisDoVeiculo').then(json => {
        this.opcionais = json
      })
    },
    getColor (method) {
      switch (method) {
        case 'POST':
          return 'success'
        case 'DELETE':
          return 'error'
        case 'PUT':
          return 'secondary'
        case 'GET':
          return 'info'
        default:
          return 'info'
      }
    }
  }
}
</script>
