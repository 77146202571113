import settings from '@/settings'

const enviarCodigo = (data, headers) => {
  return fetch(`${settings.apetrusApi}/sendzapi/EnviarCodigoVerificacao/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

const confirmarCodigo = (data, headers) => {
  return fetch(`${settings.apetrusApi}/sendzapi/VerificaCodigoWhatsApp/`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data),
    cache: 'default',
    headers: headers
  })
}

export default {
  enviarCodigo,
  confirmarCodigo
}
