<template>
  <v-card>
    <v-card-title v-show="!step !== 1 && !step !== 5" class="justify-center">
      <h2 class="grey--text">
        {{titulo}}
      </h2>
    </v-card-title>
    <v-card-text style="height: 100%">
      <v-stepper v-model="step" style="height: 100%; box-shadow: none;">
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0 ma-0">
            <v-layout justify-center>
              <v-icon color="success" size="150">local_atm</v-icon>
            </v-layout>
            <h1 class="text-center success--text" style="font-weight: 900;">
              Programa de Assinatura
            </h1>
            <p style="font-size: 1.3em; text-align: justify;" class="mt-4">
              Através do <b>Programa de Assinatura</b> você pode cobrar dos seus clientes uma mensalidade e oferecê-los descontos exclusivos.
            </p>
            <v-layout justify-center>
              <v-btn rounded color="success" @click="step = 3">Participar</v-btn>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0 ma-0">
            <v-list>
              <v-list-item v-for="(plano, index) in planos" :key="index" ripple style="height: 4em;">
                <v-list-item-icon class="pa-0">
                  <h2 style="width: 100%; font-size: 0.9em;">{{plano.valor | currency({fractionCount: 2})}}</h2>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-center">{{ plano.descricao }}</v-list-item-title>
                  <v-list-item-subtitle class="text-center">Plano {{ plano.intervalo == 1 ? 'Mensal' : 'Anual' }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-layout wrap>
                    <v-tooltip bottom>
                      <v-btn slot="activator" icon @click="planoSelecionado = plano, step = 5">
                        <v-icon color="error">close</v-icon>
                      </v-btn>
                      <span>Cancelar Plano</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <v-btn
                        slot="activator"
                        icon
                        @click="editar(plano)"
                      >
                        <v-icon color="primary">edit</v-icon>
                      </v-btn>
                      <span>Editar plano</span>
                    </v-tooltip>
                    <v-tooltip bottom class="ml-2">
                      <v-btn slot="activator" icon @click="planoSelecionado = plano, step = 4">
                        <v-icon color="info">remove_red_eye</v-icon>
                      </v-btn>
                      <span>Visualizar Plano</span>
                    </v-tooltip>
                  </v-layout>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="planos.length === 0">
                  <v-list-item-content>
                    <v-list-item-title class="text-center">Sem planos no momento</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-layout column>
              <v-btn block rounded small class="mb-2" @click="step++" color="success"><v-icon class="mr-2">add</v-icon> Adicionar Novo Plano</v-btn>
              <v-btn class="mb-2" small block rounded @click="$emit('fechar')">Fechar</v-btn>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3">
              <v-text-field
                  v-if="step === 3"
                  label="Valor do plano"
                  :disabled="planoSelecionado && !!planoSelecionado.id_efi"
                  outline
                  v-money="money"
                  v-model.lazy="valor"
                  prefix="R$"
                  :rules="[
                    v => !!v || 'Valor Inválido',
                    v => v.replaceAll('.', '').replace(',', '.') >= 5 || 'Valor precisa ser maior que R$ 4,99'
                  ]"
              ></v-text-field>
              <v-select
                  class="mt-4"
                  outline
                  :items="[
                    { text: 'Mensal', value: 1 },
                    { text: 'Anual', value: 12 }
                  ]"
                  label="Tipo de plano"
                  item-value="value"
                  v-model="intervalo"
                  hide-details
                  hide-spin-buttons
                  :disabled="!!planoSelecionado"
              ></v-select>
              <br/>
              <v-flex xs12>
                  <v-checkbox
                    v-model="tem_limite_assinantes"
                    color="primary"
                    class="mt-0 ml-2 mb-3"
                    :hide-details="tem_limite_assinantes"
                    >
                    <template slot="label">
                        <span class="body-2 grey--text">Limitar número de assinantes</span>
                    </template>
                  </v-checkbox>
                  <v-slide-x-transition>
                    <v-text-field
                        v-show="tem_limite_assinantes"
                        v-model="limite_assinantes"
                        :rules="[
                          v => !!v || 'Valor Inválido'
                        ]"
                        type="number"
                        outline
                        hide-details
                        class="pt-2"
                        label="Limite assinantes"
                        ></v-text-field>
                  </v-slide-x-transition>
                </v-flex>
              <v-layout justify-center>
                <v-flex xs5>
                  <v-checkbox
                    v-if="existeEfi"
                    v-model="is_efi"
                    :disabled="!!planoSelecionado"
                    label="É Efi"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout v-if="is_efi" column>
              <template v-if="empresaTemEfi"></template>
              <template v-else>
                <v-flex v-for="(field, index) in efi.nome_campos.split(',')" :key="index" xs12>
                  <v-text-field
                    outline
                    :label="field"
                    hide-details
                    v-model="pagamento_model.formulario[field]"
                    required
                    :rules="[
                      v => !!v || ''
                    ]"
                    class="mb-4"
                  ></v-text-field>
                </v-flex>
              </template>
              </v-layout>
              <v-layout justify-space-around>
                <v-btn
                    v-if="planos.length > 0"
                    class="mt-3 mb-2"
                    rounded
                    @click="step= planos.length > 0 ? 2 : 1, planoSelecionado = undefined"
                    :loading="loading"
                    small
                  >
                    Voltar
                </v-btn>
                <v-btn
                    class="mt-3 mb-2"
                    rounded
                    color="success"
                    :disabled="disabledBtn()"
                    @click="assinatura()"
                    :loading="loading"
                    small
                  >
                    Confirmar
                </v-btn>
              </v-layout>
          </v-stepper-content>
          <v-stepper-content step="4" class="pa-0 ma-0">
            <v-layout wrap justify-space-between v-if="planoSelecionado" style="font-size: 1.2em">
              <v-flex xs11 class="mb-2 mt-2">
                Descrição: <b>{{planoSelecionado.descricao}}</b>
              </v-flex>
              <v-flex xs5 class="mb-2">
                Valor: <b>{{planoSelecionado.valor | currency({fractionCount: 2})}}</b>
              </v-flex>
              <v-flex xs5 class="mb-2">
                Tipo de plano: <b>{{ planoSelecionado.intervalo == 1 ? 'Mensal' : 'Anual' }}</b>
              </v-flex>
              <v-flex xs5 class="mb-2">
                Para: <b>{{ planoSelecionado.empresa.nome_fantasia }}</b>
              </v-flex>
              <v-flex xs5 class="mb-2">
                Número de Assinantes: <b>{{ planoSelecionado.total_assinantes }}</b>
              </v-flex>
              <v-flex xs11>
                Data de encerramento do ultimo assinante: <b>{{
                planoSelecionado.data_vencimento_ultimo_assinante ?
                planoSelecionado.data_vencimento_ultimo_assinante :
                'Ainda não há assinantes'
              }}</b>
              </v-flex>
            </v-layout>
            <v-layout column class="mt-3">
              <v-btn small rounded class="mb-2" color="error" @click="step = 5">
                Cancelar Plano
              </v-btn>
              <v-btn small rounded class="mb-2" @click="step = 2, planoSelecionado = undefined">
                Voltar
              </v-btn>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="5" class="pa-0 ma-0">
            <h2 class="mb-3 text-center error--text">
              Tem certeza que deseja cancelar o programa de assinatura?
            </h2>
              <h4 class="mb-3 mr-2 ml-2 grey--text">
                {{
                  planoSelecionado && planoSelecionado.data_vencimento_ultimo_assinante ?
                  `Esse plano contém assinates, você poderá excluir o plano, mas o benefícios ficarão disponiveis até a data de termino da assinatura do último assinante ${planoSelecionado.data_vencimento_ultimo_assinante}` :
                  'Esse plano não possui assinantes, você pode excluir esse plano e todos os beneficios criados.'
                }}
              </h4>
            <v-layout justify-space-around>
              <v-btn rounded @click="step = 2" class="mb-2" small>Não</v-btn>
              <v-btn rounded color="error" class="mb-2" dark @click="cancelarAssinatura" small>Sim</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'

export default {
  directives: { money: VMoney },
  data: () => ({
    step: 1,
    valor: '5,00',
    intervalo: 1,
    limite_assinantes: 0,
    tem_limite_assinantes: false,
    planos: [],
    planoSelecionado: undefined,
    is_efi: false,
    existeEfi: false,
    empresaTemEfi: false,
    efi: undefined,
    pagamento_model: {
      formulario: {},
      aceitar_credito: true,
      aceitar_debito: true,
      posto: null,
      is_admin: false
    },
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    }
  }),
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    titulo () {
      switch (this.step) {
        case 2: return 'SEUS PLANOS'
        case 3: return 'CADASTRAR PLANO'
        case 4: return 'VISUALIZAR PLANO'
      }
      return undefined
    }
  },
  async beforeMount () {
    this.carregarFormasPagamento()
    this.carregarMeiosDeRecebimento()
    if (!this.empresa) {
      this.$store.dispatch('setError', 'error', 'Não foi encontrado uma empresa em sua conta')
      this.$emit('fechar')
    }
    await this.pegarPlanos()
    this.step = this.planos.length > 0 ? 2 : 1
  },
  methods: {
    pegarPlanos () {
      const data = {
        ativo: true,
        empresa: this.empresa.cnpj
      }
      this.$store.dispatch('pegarPlanos', data).then(json => {
        this.planos = json
        this.step = this.planos.length > 0 ? 2 : 1
      })
    },
    carregarFormasPagamento () {
      let lista = []
      this.$store.dispatch('carregarFormasPagamento', this.pagination).then(json => {
        lista = json.filter((p) => p.descricao === 'efi')
        if (lista.length > 0) {
          this.efi = lista[0]
          this.existeEfi = true
        } else {
          this.existeEfi = false
        }
      })
    },
    carregarMeiosDeRecebimento () {
      let lista = []
      this.$store.dispatch('listPagamentosExtra').then(json => {
        lista = json.filter((p) => p.forma_pagamento && p.forma_pagamento.descricao === 'efi')
        if (lista.length > 0) {
          this.empresaTemEfi = true
        } else {
          this.empresaTemEfi = false
        }
      })
    },
    cadastrarEfi () {
      this.$store.dispatch(
        'vincularPagamentoExtra',
        {
          body: {
            ...this.pagamento_model,
            empresa: this.empresa.cnpj
          },
          pagamento: this.efi.id_forma_pagamento,
          naoCarregar: true
        }
      ).then(() => {
        this.carregarMeiosDeRecebimento()
        this.$store.dispatch('setSuccess', 'Meio de recebimento Efi foi inserido com sucesso.')
      }).catch(() => {
        this.$store.dispatch('setError', 'Não foi possível inserir meio de recebimento Efi.')
      })
    },
    cancelarAssinatura () {
      this.$store.dispatch('deletarAssinatura', {
        id_plano: this.planoSelecionado.id
      }).then(() => {
        this.$store.dispatch('setSuccess', 'Plano deletado com sucesso')
        this.pegarPlanos()
        this.planoSelecionado = undefined
      })
    },
    editar (plano) {
      this.planoSelecionado = plano
      this.valor = plano.valor
      this.intervalo = plano.intervalo
      this.limite_assinantes = plano.qtd_assinantes
      this.tem_limite_assinantes = plano.qtd_assinantes != null
      this.step = 3
    },
    disabledBtn () {
      if (this.planoSelecionado && this.valor && this.planoSelecionado.valor === this.valor.replaceAll('.', '').replace(',', '.') && parseInt(this.planoSelecionado.qtd_assinantes) === parseInt(this.limite_assinantes) && (this.planoSelecionado.qtd_assinantes != null) === this.tem_limite_assinantes) return true
      return false
    },
    assinatura () {
      const data = {}

      if (this.valor === '0,00') {
        this.$store.dispatch('setError', 'Valor Inválido.')
      }

      data.empresa = this.empresa.cnpj

      if (this.limite_assinantes) data.qtd_assinantes = this.limite_assinantes
      if (!this.tem_limite_assinantes) data.qtd_assinantes = undefined

      if (this.valor) {
        data.valor_assinatura = this.valor.replaceAll('.', '').replace(',', '.')
        data.intervalo = this.intervalo
      }

      if (this.is_efi && !this.empresaTemEfi) {
        this.cadastrarEfi()
      }

      if (this.planoSelecionado) data.id_plano = this.planoSelecionado.id

      data.is_efi = this.is_efi
      this.$store.dispatch('cadastrarAssinatura', data).then(mensagem => {
        this.$store.dispatch('setSuccess', mensagem)
        this.pegarPlanos()
      }).catch(error => {
        this.$store.dispatch('setError', error)
        this.pegarPlanos()
      })
    }
  }
}
</script>
