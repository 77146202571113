<template>
  <v-container pa-5>
    <v-row class="pb-12 align-center">
      <v-col cols="auto" class="d-flex align-center">
        <v-btn icon class="mr-3" large style="color: #333; font-weight: bold;" @click="$emit('fechar')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-card-title class="text-h4 font-weight-bold">Consultas Veiculares</v-card-title>
      </v-col>
    </v-row>
    <v-row class="pa-5 justify-center align-center">
      <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4" lg="4" xl="4">
        <v-card width="320px" height="450px" outlined elevation="10" class="py-5 px-3 rounded-xl d-flex flex-column align-center">
          <v-card-text v-if="card.tag" class="font-weight-bold text-center primary white--text" style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; font-size: 1.2em;">
            {{ card.tag }}
          </v-card-text>
          <v-card-title class="font-weight-bold text-h5 text-center mt-10">{{ card.titulo }}</v-card-title>
          <v-card-text class="text-center mt-2" style="height: 100px; overflow: hidden;">{{ card.descricao }}</v-card-text>
          <v-card-text class="text-center font-weight-bold text-h4"> R$ {{ card.taxa }}<v-card-text>Por Consulta</v-card-text>
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-end">
            <v-btn rounded="xl"
              size="x-large" block
              style="font-size: 0.9em; padding: 15px; color: white"
              :color="card.id === 6 ? '#f58220' : 'primary'"
              @click="abrirDialog(card)">Comprar Consulta</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogOpen" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmar Consulta</v-card-title>
        <v-card-text>
          <p v-if="cardSelecionado">Confirme os detalhes da consulta para o serviço <strong>{{ cardSelecionado.titulo }}</strong>.</p>
          <v-text-field
            label="Placa do Veículo"
            v-model="placa"
            :rules="[obrigatorio.required]"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialogOpen = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="confirmarConsulta">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  props: ['veiculo'],
  data () {
    return {
      dialogOpen: false,
      cardSelecionado: null,
      placa: '',
      obrigatorio: {
        required: v => !!v || 'Campo obrigatório'
      },
      cards: {
        veiculo_completo: { id: 1, tag: 'Recomendado', titulo: 'Veículo Completo', descricao: 'A consulta mais completa do mercado, com informações e dados exclusivos, que só a Olho no Carro tem!', taxa: '0,00' },
        veiculo_essencial: { id: 2, titulo: 'Veículo Essencial', descricao: 'Informações essenciais sobre o veículo, que te ajudam a negociar com mais segurança e acertar na escolha do seu próximo carro.', taxa: '0,00' },
        veiculo_leilao: { id: 3, titulo: 'Veículo Leilão', descricao: 'Informações sobre o veículo de leilão, que te ajudam a negociar o melhor valor e evitar prejuízos!', taxa: '0,00' },
        veiculo_gravame: { id: 4, titulo: 'Veículo Gravame', descricao: 'Informações sobre o status de financiamento do veículo que te ajudam a evitar problemas na hora da transferência!', taxa: '0,00' },
        dados_cadastrais: { id: 5, titulo: 'Dados Cadastrais', descricao: 'Informações sobre a situação do veículo que te ajudam a validar dados cadastrais nacionais e estaduais!', taxa: '0,00' },
        debitos_multas: { id: 6, titulo: 'Débitos e Multas', descricao: 'Informações sobre a situação do veículo que te ajudam a validar dados cadastrais nacionais e estaduais!', taxa: '0,00' }
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    abrirDialog (card) {
      if (card.id === 6) {
        window.location.href = 'https://bssolucoes.parceirozig.com.br/parceiro/apetrus/consulta-veiculo?partnerConsultFlow=true'
      } else {
        this.cardSelecionado = card
        this.dialogOpen = true
      }
    },
    confirmarConsulta () {
      if (!this.placa) {
        console.log('Placa do veículo é obrigatória.')
        return
      }

      const payload = {
        empresa: this.empresa.cnpj,
        consulta: this.cardSelecionado ? this.cardSelecionado.id : '',
        placa: this.placa
      }
      this.$store.dispatch('fazerConsultaChecktudo', payload)
        .then(json => {
          if (json) {
            console.log(json)
          }
        })
        .catch(() => {
          console.log('Houve um erro ao fazer a consulta.')
        })

      this.dialogOpen = false
    },
    pegarTaxas () {
      this.$store.dispatch('pegarTaxasChecktudo')
        .then(json => {
          if (json) {
            this.cards.veiculo_completo.taxa = json.taxa_completa ? json.taxa_completa.replace('.', ',') : '0,00'
            this.cards.veiculo_essencial.taxa = json.taxa_essencial ? json.taxa_essencial.replace('.', ',') : '0,00'
            this.cards.veiculo_leilao.taxa = json.taxa_leilao ? json.taxa_leilao.replace('.', ',') : '0,00'
            this.cards.veiculo_gravame.taxa = json.taxa_gravame ? json.taxa_gravame.replace('.', ',') : '0,00'
            this.cards.dados_cadastrais.taxa = json.taxa_cadastrais ? json.taxa_cadastrais.replace('.', ',') : '0,00'
            this.cards.debitos_multas.taxa = json.taxa_multas ? json.taxa_multas.replace('.', ',') : '0,00'
          }
        })
        .catch(() => {
          console.log('Nenhuma taxa foi encontrada.')
        })
    }
  },
  mounted () {
    this.pegarTaxas()
  }
}
</script>
