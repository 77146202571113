import settings from '@/settings'

const fazerConsulta = (data, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/consultas/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const pegarTaxas = (headers) => {
  return fetch(`${settings.apetrusApi}/checktudo/`, {
    method: 'GET',
    mode: 'cors',
    headers: headers
  })
}

export default {
  fazerConsulta,
  pegarTaxas
}
