<template>
  <v-card>
      <v-container pa-5>
          <v-row class="pb-12 align-center">
              <v-col cols="auto" class="d-flex align-center">
                  <v-btn icon class="mr-3" large style="color: #333; font-weight: bold;" @click="$emit('fechar')">
                      <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
              </v-col>
              <v-col cols="auto">
                  <v-card-title class="text-h4 font-weight-bold">Consultas Veiculares</v-card-title>
              </v-col>
          </v-row>
          <v-row class="pa-5 justify-center align-center">
          <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4" lg="4" xl="4">
              <v-card width="320px" height="450px" outlined elevation="10" class="py-5 px-3 rounded-xl d-flex flex-column align-center">
              <v-card-text v-if="card.tag" class="font-weight-bold text-center primary white--text" style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); width: 100%; font-size: 1.2em;">
                  {{ card.tag }}
              </v-card-text>
              <v-card-title class="font-weight-bold text-h5 text-center mt-10">{{ card.titulo }}</v-card-title>
              <v-card-text class="text-center mt-2" style="height: 100px; overflow: hidden;">{{ card.descricao }}</v-card-text>
              <v-card-text class="text-center font-weight-bold text-h4"> R$ {{ card.taxa }}<v-card-text>Por Consulta</v-card-text>
              </v-card-text>
              <v-card-actions class="d-flex justify-center align-end">
                  <v-btn rounded="xl" size="x-large" block :color="card.id === 6 ? '#f58220' : 'primary'" style="font-size: 0.9em; padding: 15px; color: white;" @click="fazerConsulta(card)">Comprar Consulta</v-btn>
              </v-card-actions>
              </v-card>
          </v-col>
          </v-row>
      </v-container>
  </v-card>
</template>
<script>
import JsPDF from 'jspdf'
export default {
  props: ['veiculo'],
  data () {
    return {
      cards: {
        veiculo_completo: { id: 1, tag: 'Recomendado', titulo: 'Veículo Completo', descricao: 'A consulta mais completa do mercado, com informações e dados exclusivos, que só a Olho no Carro tem!', taxa: '0,00' },
        veiculo_essencial: { id: 2, titulo: 'Veículo Essencial', descricao: 'Informações essenciais sobre o veículo, que te ajudam a negociar com mais segurança e acertar na escolha do seu próximo carro.', taxa: '0,00' },
        veiculo_leilao: { id: 3, titulo: 'Veículo Leilão', descricao: 'Informações sobre o veículo de leilão, que te ajudam a negociar o melhor valor e evitar prejuízos!', taxa: '0,00' },
        veiculo_gravame: { id: 4, titulo: 'Veículo Gravame', descricao: 'Informações sobre o status de financiamento do veículo que te ajudam a evitar problemas na hora da transferência!', taxa: '0,00' },
        dados_cadastrais: { id: 5, titulo: 'Dados Cadastrais', descricao: 'Informações sobre a situação do veículo que te ajudam a validar dados cadastrais nacionais e estaduais!', taxa: '0,00' },
        debitos_multas: { id: 6, titulo: 'Débitos e Multas', descricao: 'Informações sobre a situação do veículo que te ajudam a validar dados cadastrais nacionais e estaduais!', taxa: '0,00' }
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  methods: {
    pegarTaxas () {
      this.$store.dispatch('pegarTaxasChecktudo')
        .then(json => {
          if (json) {
            this.cards.veiculo_completo.taxa = json.taxa_completa ? json.taxa_completa.replace('.', ',') : '0,00'
            this.cards.veiculo_essencial.taxa = json.taxa_essencial ? json.taxa_essencial.replace('.', ',') : '0,00'
            this.cards.veiculo_leilao.taxa = json.taxa_leilao ? json.taxa_leilao.replace('.', ',') : '0,00'
            this.cards.veiculo_gravame.taxa = json.taxa_gravame ? json.taxa_gravame.replace('.', ',') : '0,00'
            this.cards.dados_cadastrais.taxa = json.taxa_cadastrais ? json.taxa_cadastrais.replace('.', ',') : '0,00'
            this.cards.debitos_multas.taxa = json.taxa_multas ? json.taxa_multas.replace('.', ',') : '0,00'
          }
        })
        .catch(() => {
          console.log('Nenhuma taxa foi encontrada.')
        })
    },
    fazerConsulta (card) {
      const payload = {
        empresa: this.empresa.cnpj
        // placa: this.veiculo.identificacao,
        // chassi: this.veiculo.chassi,
        // motor: this.veiculo.motor,
        // renavam: this.veiculo.renavam,
        // uf: this.veiculo.
      }
      if (card.id === 1) {
        payload.consulta = 'veiculo_completo'
        payload.placa = 'ABC1234'
      }
      if (card.id === 2) {
        payload.consulta = 'veiculo_essencial'
        payload.placa = 'ABC1234'
      }
      if (card.id === 3) {
        payload.consulta = 'veiculo_leilao'
      }
      if (card.id === 4) {
        payload.consulta = 'veiculo_gravame'
      }
      if (card.id === 5) {
        payload.consulta = 'veiculo_completo'
      }
      if (card.id === 6) {
        window.location.href = 'https://bssolucoes.parceirozig.com.br/parceiro/apetrus/consulta-veiculo?partnerConsultFlow=true&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content=&ip=&origin=&keyword='
      }
      this.$store.dispatch('fazerConsultaChecktudo', payload)
        .then(json => {
          if (json) {
            this.toPdf(json)
          }
        })
        .catch(() => {
          console.log('houve um erro ao fazer consulta')
        })
    },
    toPdf (json) {
      const doc = new JsPDF()
      doc.setFontSize(14)
      doc.text('Consulta Veicular', 10, 10)
      let y = 20
      json.body.forEach((item) => {
        for (const [key, value] of Object.entries(item)) {
          doc.text(`${key}: ${value}`, 10, y)
          y += 10
        }
        y += 10
      })
      doc.save('Apetrus_ConsultaVeicular.pdf')
    }
  },
  mounted () {
    this.pegarTaxas()
  }
}
</script>
