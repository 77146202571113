<template>
  <v-card style="border-radius: 1em;" :loading="loading">
    <v-card-title
      class="justify-center"
    >
      <h3>ADICIONAR CRÉDITO</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      v-show="alerta"
      :type="tipo"
      class="mt-4 mb-1 pa-2"
      :value="true"
    >
      <h4 justify-center>{{alerta}}</h4>
    </v-alert>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step" style="box-shadow: none; height: 100%;">
        <v-stepper-items style="height: 100%;">
          <v-stepper-content step="1" v-if="step == 1" style="height: 100%;">
            <v-text-field
              label="Valor"
              v-money="money"
              v-model.lazy="valor"
              prefix="R$"
              class="ml-4 mr-4"
              :rules="[
                (v) => !!v || 'Valor Inválido',
                (v) => formatarvalor(v) > 0 || 'Valor Inválido'
              ]"
              hint="Insira um valor a partir de R$ 1,00"
            />
            <v-select
              v-model="forma_insercao"
              :items="[
                { text: 'Instantâneo', value: 1 },
                { text: 'Padrão', value: 2 }
              ]"
              class="ml-4 mr-4"
              label="Forma de inserção"
            />
            <v-select
              v-show="forma_insercao == 1"
              v-model="meio_recebimento"
              :items="meios_recebimento"
              item-text="tipo_pagamento.descricao"
              class="ml-4 mr-4"
              label="Forma de Pagamento"
              return-object
            />
            <template v-if="meio_recebimento && meio_recebimento.tipo_pagamento && meio_recebimento.tipo_pagamento.cartao">
              <h2 class="text-center mb-3">PAGAMENTO COM CARTÃO</h2>
              <v-card v-if="cartao && !loading" class="ma-2 pa-2" elevation="3" style="border-radius: 1em;">
                <v-layout>
                  <v-flex xs2 align-self-center>
                    <v-icon size="50" color="info" class="ml-1">mdi-credit-card</v-icon>
                  </v-flex>
                  <v-flex x10>
                    <template v-if="cartao.final">
                      <h2>{{ (cartao.descricao || '').toUpperCase() }}</h2>
                      <h5>Final: {{ cartao.final }}</h5>
                    </template>
                    <template v-else>
                      Cartão não encontrado
                    </template>
                    <v-layout
                      justify-center
                      v-if="meio_recebimento.tipo_pagamento.referencia == 'credito'"
                    >
                      <v-text-field
                        label="CVV"
                        type="password"
                        outlined
                        class="pa-0 mr-2 mt-1"
                        v-mask="'###'"
                        v-model="cvv"
                        required
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-layout justify-center v-else-if="!cartao && !loading">
                <h4 class="error--text">Você não possui um cartão ativo</h4>
              </v-layout>
              <v-layout justify-center v-else>
                <v-progress-circular
                  size="50"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </v-layout>
            </template>
          </v-stepper-content>
          <v-stepper-content step="2" style="height: 100%;">
            <h4>Formas de pagamento da Apetrus</h4>
            <v-expansion-panels v-if="meiosDeRecebimento.length > 0">
              <v-expansion-panel
                v-for="(item,i) in meiosDeRecebimento"
                :key="i"
              >
                <v-expansion-panel-header>
                  {{item.descricao}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-flex v-if="item.chave_pix">
                    <h5>Chave PIX: {{item.chave_pix}}</h5>
                    <v-layout justify-center>
                      <v-btn @click="copiar(item.chave_pix)" color="info" icon><v-icon>content_copy</v-icon></v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex v-if="item.cod_boleto">
                    <h5>Código do Boleto: {{item.cod_boleto}}</h5>
                    <v-layout justify-center>
                      <v-btn @click="copiar(item.cod_boleto)" color="info" icon><v-icon>content_copy</v-icon></v-btn>
                    </v-layout>
                  </v-flex>
                  <h5 style="width: 100%; text-align: center;" v-if="item.banco">{{item.banco}}</h5>
                  <h5 v-if="item.agencia">Agencia: {{item.agencia}}</h5>
                  <h5 v-if="item.conta">Conta: {{item.conta}}</h5>
                  <h5 v-if="item.tipo_conta">{{tipos_conta[item.tipo_conta - 1].descricao}}</h5>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-layout justify-center class="mt-4">
              <v-card v-if="!arquivoAnexado && !imagemAnexada" @click="$refs.imageInput.click()" style="min-width: 250px; min-height: 250px;" color="#DCDCDC" hover rounded>
                <v-layout justify-center align-center fill-height>
                    <v-icon size="100">perm_media</v-icon>
                </v-layout>
              </v-card>
              <img v-if="imagemAnexada" :src="imagemAnexada" alt="imagem do comprovante" width="300" @click="$refs.imageInput.click()">
              <object v-if="arquivoAnexado" width="300" height="300" type="application/pdf" :data="arquivoAnexado" @click="$refs.imageInput.click()"></object>
              <input
                  type="file"
                  style="display:none"
                  accept="image/pdf*"
                  ref="imageInput"
                  @input="anexar"
              />
            </v-layout>
            <v-layout justify-center class="mt-4 mb-4">
              <v-btn @click="$refs.imageInput.click()" rounded>ANEXAR COMPROVANTE</v-btn>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3" style="height: 100%;">
            <h4
              style="text-align: center;"
            >
              Insira o comprovante de pagamento
            </h4>
          </v-stepper-content>
          <v-stepper-content
            step="4"
          >
            <h2
              v-if="!qrcode"
              class="
                text-center
                error--text
              "
            >
              Por favor! Aguarde a geração do Qrcode
            </h2>
            <v-flex
              xs12
              class="ma-0"
              v-else
            >
              <h2
                class="
                  text-center
                  success--text
                "
              >
                Pague com PIX através do QRcode!
              </h2>
              <v-flex
                justify-center
                id="print"
                xs12
                class="ma-0 pa-0"
              >
              <v-container
                pa-0
                fluid
                class="ma-0 pa-0"
              >
                <v-img
                  id="mainImg"
                  :lazy-src="qrcode"
                  :src="qrcode"
                  v-if="qrcode"
                ></v-img>
              </v-container>
              </v-flex>
              <v-btn
                  color="green"
                  dark
                  block
                  @click="printData()"
                  class="ma-4"
                >
                Imprimir QRcode
              </v-btn>
            </v-flex>
          </v-stepper-content>
          <v-stepper-content step="5" style="height: 100%;">
            <h2 class="success--text text-center ma-4">
              Seu pagamento foi realizado com sucesso.<br/><br/>
              Seu crédito será adicionado após a confirmação do recebimento feita pelo sistema
            </h2>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="justify-space-around">
      <v-flex xs5>
        <v-btn
          block
          text
          @click="cancelar"
        >
        Fechar
      </v-btn>
      </v-flex>
      <v-flex xs5>
        <v-btn
          block
          text
          color="success"
          @click="next"
          :loading="loadingCompra"
          :disabled="
            (step == 1 && forma_insercao == 1 && !meio_recebimento) ||
            formatarvalor(valor) < 1 ||
            step >= 4 || loadingCompra
          "
        >
          Avançar
        </v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'
import * as Printjs from 'print-js'
import { mask } from 'vue-the-mask'

export default {
  directives: { money: VMoney, mask },
  data: () => ({
    step: 1,
    loadingCompra: false,
    valor: '0,00',
    forma_insercao: 1,
    arquivoAnexado: undefined,
    imagemAnexada: undefined,
    meios_recebimento: [],
    meiosDeRecebimento: [],
    meio_recebimento: undefined,
    interval: undefined,
    contador: 0,
    txid: undefined,
    alerta: undefined,
    tipo: 'error',
    cvv: undefined,
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    desserts: [],
    tipos_conta: [
      { descricao: 'Conta Corrente', id: 1 },
      { descricao: 'Conta Poupança', id: 2 },
      { descricao: 'Conta Conjunta', id: 3 },
      { descricao: 'Conta Salário', id: 4 }
    ]
  }),
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    empresa () {
      return this.$store.getters.empresa
    },
    qrcode () {
      return this.$store.getters.qrcodecobranca
    },
    loading () {
      return this.$store.getters.loading
    },
    cartoes () {
      return this.$store.getters.cartoes || []
    },
    cartao () {
      const cartao = this.$store.getters.cartao
      if (cartao && typeof cartao === 'object') {
        const cartaoSelecionado = this.desserts.find((c) => {
          return c.id_cartao === cartao.id_cartao
        })
        return {
          id_cartao: cartao.id_cartao,
          descricao: cartaoSelecionado ? cartaoSelecionado.descricao : null,
          final: cartaoSelecionado ? cartaoSelecionado.final : null,
          hash_code: cartao.hash_code
        }
      } else if (cartao && typeof cartao === 'string') {
        const idCartao = Number(cartao.split('-')[0])
        const cartaoSelecionado = this.desserts.find((c) => {
          return c.id_cartao === idCartao
        })
        return {
          id_cartao: idCartao,
          hash_code: cartao.split('-')[1],
          descricao: cartaoSelecionado ? cartaoSelecionado.descricao : null,
          final: cartaoSelecionado ? cartaoSelecionado.final : null
        }
      }
      return this.$store.getters.cartao
    }
  },
  watch: {
    meio_recebimento (val) {
      if (val && val.tipo_pagamento && val.tipo_pagamento.cartao) {
        this.listarcartoes()
      }
    }
  },
  methods: {
    listarcartoes () {
      this.$store.dispatch('listarCartoes', this.pessoa.id_pessoa).then(cartoes => {
        this.desserts = cartoes
      })
        .catch(error => {
          this.$emit('error', error)
        })
    },
    formatarvalor (valor) {
      if (!valor) return 0
      if (typeof valor === 'number') return valor
      return Number(
        valor.replaceAll('.', '').replace(',', '.')
      )
    },
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    clearInput () {
      this.txid = undefined
      this.valor = '0,00'
      this.step = 1
      this.meio_recebimento = undefined
    },
    cancelar () {
      if (this.txid) {
        const meioRecebimento = this.meio_recebimento && this.meio_recebimento.pagamento ? this.meio_recebimento.pagamento.descricao.toLowerCase() : undefined
        if (meioRecebimento === 'efipix') {
        } else if (meioRecebimento === 'pagpixbank') {
          this.deletarTransacao()
        }
      }
      this.clearInput()
      this.$emit('fechar')
    },
    next () {
      this.loadingCompra = true
      switch (this.step) {
        case 1:
          this.valor = Number(this.valor.replaceAll('.', '').replace(',', '.'))
          if (!this.valor || typeof this.valor === 'string' || this.valor <= 0) {
            this.activeAlert('Valor inválido', 'error')
            return undefined
          }
          if (this.forma_insercao === 1) this.comprarCashBack()
          else {
            this.pegarMeiosDeRecebimento()
            this.step = this.forma_insercao !== 1 ? 2 : 4
          }
          this.loadingCompra = false
          break
        case 2:
          this.inserir().then((value) => {
            this.loadingCompra = false
          })
          break
        case 3 | 4:
          this.cancelar().then((value) => {
            this.loadingCompra = false
          })
          break
        case 5:
          this.$emit('fechar')
      }
    },
    pegarMeiosDeRecebimento () {
      this.$store.dispatch('pegarMeiosDeRecebimentoApetrus').then(json => {
        this.meiosDeRecebimento = json
      })
    },
    copiar (texto) {
      navigator.clipboard.writeText(texto)
      this.activeAlert('Copiado', 'info')
    },
    anexar (event) {
      this.arquivoAnexado = undefined
      this.imagemAnexada = undefined
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const arquivo = event.target.result
        if (file.type.indexOf('image/') !== -1) {
          this.imagemAnexada = arquivo
        } else {
          this.arquivoAnexado = arquivo
        }
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    },
    inserir () {
      const currentDate = `${this.empresa.cnpj}/${(new Date()).valueOf().toString()}`
      this.$store.dispatch('inserirFirebase', {
        prefixo: 'comprovante',
        id: currentDate,
        local: 'credito',
        arquivo: this.imagemAnexada || this.arquivoAnexado,
        tipo: this.imagemAnexada ? 'png' : 'pdf'
      }).then(downloadURL => {
        this.$store.dispatch('adicaoDeCredito',
          {
            comprovante: downloadURL,
            valor: this.valor,
            method: 'POST'
          }
        ).then(() => {
          this.$store.dispatch('setSuccess', 'Comprovante inserido com sucesso.')
          this.$emit('update')
          this.$emit('fechar')
        }).catch(() => {
          this.$store.dispatch('setError', 'Não foi possível inserir seu comprovante')
        })
      })
    },
    getMeiosRecebimento () {
      this.$store.dispatch('getMeiosRecebimento', {
        is_admin: true,
        meio_pagamento_ativo__destino__in: [3, 4]
      }).then(json => {
        this.meios_recebimento = json
      })
    },
    comprarCashBack () {
      if (!this.meio_recebimento) {
        this.step = 1
        this.activeAlert('Não foi selecionado um tipo de pagamento', 'error')
        return
      }
      this.$store.dispatch('comprarCashBack', {
        empresa: this.empresa.cnpj,
        valor: this.valor,
        id_tipo_pagamento: this.meio_recebimento.tipo_pagamento.id_tipo_pagamento
      }).then(json => {
        const data = {
          codigo: json.codigo,
          is_apetrus: true,
          id_compra: json.id_compra,
          valor: this.valor.toString()
        }
        this.realizarPagamento(data)
      })
    },
    realizarPagamento (data) {
      if (this.meio_recebimento.pagamento) {
        const meioRecebimento = this.meio_recebimento.pagamento.descricao.toLowerCase()
        if (this.meio_recebimento.pagamento.pagamento_secundario) {
          data[meioRecebimento === 'pagpixbank' ? 'cnpj_empresa' : 'empresa'] = this.empresa.cnpj
          data.vendedor = 3
          data.comprador = 2
        } else {
          if (!this.cartao) {
            this.activeAlert('Você precisa cadastrar um cartão', 'error')
            return
          }
          data.cartao = this.cartao.hash_code
          data.cvv = this.cvv
          data.id_cartao = this.cartao.id_cartao
          data.comprador = 2
          data.vendedor = 3
          // if (meioRecebimento === 'linx') {
          // } else if (meioRecebimento === 'e-rede') {
          //   data.user_agent = null
          // } else if (meioRecebimento === 'getnet') {
          // } else if (meioRecebimento === 'cielo') {
          // } else if (meioRecebimento === 'mundipagg') {
          // } else if (meioRecebimento === 'picpay') {
          // }
        }
        const funcao = this.$store.dispatch('pagamento', {
          url: this.meio_recebimento.pagamento.url,
          body: data,
          pagamento_secundario: this.meio_recebimento.pagamento.pagamento_secundario,
          nomeMeioRecebimento: meioRecebimento,
          method: 'POST'
        })
        if (this.meio_recebimento.pagamento.pagamento_secundario) {
          funcao.then(json => {
            this.txid = json.txid
            this.step = this.meio_recebimento.pagamento.pagamento_secundario ? 4 : 5
            this.contador = 0
            this.interval = setInterval(
              () => {
                if (!this.txid || this.contador >= 20) { // 20 * 15 segundos = 5 minutos
                  clearInterval(this.interval)
                } else this.verificarPagamento()
                this.contador++
              },
              15000
            )
          }).catch(() => {
            this.step = 1
          })
        } else {
          funcao.then(() => {
            this.step = 5
            this.$emit('update')
          }).catch(() => {
            this.activeAlert('Não foi possível realizar pagamento', 'error')
          })
        }
      } else {
        this.activeAlert(
          'Meio de pagamento não informado',
          'error'
        )
      }
    },
    verificarPagamento () {
      const meioRecebimento = this.meio_recebimento && this.meio_recebimento.pagamento ? this.meio_recebimento.pagamento.descricao.toLowerCase() : undefined
      const data = {
        method: 'GET',
        pagamento_secundario: this.meio_recebimento.pagamento.pagamento_secundario,
        nomeMeioRecebimento: this.meio_recebimento.pagamento.descricao.toLowerCase(),
        url: this.meio_recebimento.pagamento.url,
        body: {
          is_apetrus: true,
          vendedor: 3
        }
      }
      data.body.txid = this.txid
      this.$store.dispatch('pagamento', data).then(json => {
        if (meioRecebimento === 'efipix' || meioRecebimento === 'bancointer') {
          if (json.status === 'CONCLUIDA' || json.status === 'PAGA') {
            this.$emit('update')
            this.activeAlert(
              'O Pagamento foi realizado com sucesso',
              'success'
            )
            this.$store.dispatch('setSuccess', 'O Pagamento foi realizado com sucesso')
            this.step = 5
            if (this.interval) clearInterval(this.interval)
          } else if (json.status === 'REMOVIDA' || json.status === 'EXPIRADA') {
            this.activeAlert(
              'A transação foi cancelada',
              'error'
            )
            this.$store.dispatch('setError', 'A transação foi cancelada')
          }
        } else if (meioRecebimento === 'pagpixbank') {
          if (json.status === 'Aprovada') {
            this.$emit('update')
            this.activeAlert(
              'O Pagamento foi realizado com sucesso',
              'success'
            )
            this.$store.dispatch('setSuccess', 'O Pagamento foi realizado com sucesso')
            this.step = 5
            if (this.interval) clearInterval(this.interval)
          } else if (json.status === 'DEVOLVIDA') {
            this.activeAlert(
              'A transação foi cancelada',
              'error'
            )
            this.$store.dispatch('setError', 'A transação foi cancelada')
          }
        }
      })
    },
    deletarTransacao () {
      const data = {
        method: 'DELETE',
        pagamento_secundario: this.meio_recebimento.pagamento.pagamento_secundario,
        nomeMeioRecebimento: this.meio_recebimento.pagamento.descricao.toLowerCase(),
        url: this.meio_recebimento.pagamento.url,
        body: {
          txid: this.txid,
          is_apetrus: true
        }
      }
      this.$store.dispatch('pagamento', data)
        .then(() => this.clearInput())
    },
    printData () {
      Printjs(this.qrcode, 'image')
    }
  },
  beforeMount () {
    this.getMeiosRecebimento()
  }
}
</script>
